import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FIELDS } from '@/components/route/listing/AskHostModal/AskHostModal';
import { ALERT_OCTAGON } from '@/components/switchback/Icon/assets';
import CustomLink from '@/components/switchback/Link/Link';
import Notice, { NoticeType } from '@/components/switchback/Notice/Notice';

import { FormData } from '../AskHostModal/AskHostModalContainer';

interface IProps {
  form: UseFormReturn<FormData>;
  formId: string;
  buttonLabel: string;
}

const AskHostFooter: React.FC<IProps> = ({ form, formId, buttonLabel }) => {
  const intl = useIntl();

  return (
    <>
      <div className="autoType300">
        {form?.formState.errors[FIELDS.MESSAGE] ? (
          <div className="text-red-800">
            <Notice icon={ALERT_OCTAGON} variant={NoticeType.critical}>
              {form.formState.errors[FIELDS.MESSAGE].message}
            </Notice>
          </div>
        ) : (
          <Notice icon={ALERT_OCTAGON} variant={NoticeType.info}>
            {intl.formatMessage({
              defaultMessage: 'Always communicate through Outdoorsy',
              id: 'BhHDqp',
            })}
          </Notice>
        )}

        <p className="mt-1 text-gray-500 autoType200">
          {intl.formatMessage(
            {
              defaultMessage:
                'All payment transactions should be made through <a>Outdoorsy.com</a>. If an Outdoorsy host asks you to pay using Zelle, Venmo, Cash App, PayPal, Western Union, wire transfer or any other off-platform methods, report it to Outdoorsy. All payment transactions through an off-platform method will not be eligible to receive refunds.',
              id: 'Dj8JVP',
            },
            {
              a: (text: React.ReactNode) => (
                <CustomLink href="https://outdoorsy.com">{text}</CustomLink>
              ),
            },
          )}
        </p>
      </div>
      <Button
        type="submit"
        data-testid="send-message-btn"
        form={formId}
        className="w-full mt-6 ml-4 whitespace-nowrap md:w-auto md:mt-0"
        variant={EButtonColorVariant.Primary}
        label={buttonLabel}
      />
    </>
  );
};

export default AskHostFooter;
