import { Avatar as BonfireAvatar, EAvatarSize } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Avatar from '@/components/switchback/Avatar';
import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import Heading from '@/components/switchback/Heading/Heading';

type TCampgroundDataProps = {
  name: string;
  photoUrl?: string;
  avatarName: string;
  email: string;
  //Not everytime we will recieve this prop, we will just set it to false as default
  isOutdoorsyProperty?: boolean;
};

export const CampgroundData = ({
  name,
  photoUrl,
  avatarName,
  email,
  isOutdoorsyProperty = false,
}: TCampgroundDataProps) => {
  return (
    <div>
      <div className="flex items-start justify-between md:justify-start md:items-center">
        <div className="mb-1 md:order-2">
          <Heading level={2} className={`text-700 md:text-800 highlight mb-1 md:my-0`}>
            <FormattedMessage
              defaultMessage="Meet your host, {name}"
              id="FHzDh5"
              values={{ name }}
            />
          </Heading>
        </div>

        <div className="w-16 h-16 ml-4 text-center md:order-1 md:ml-0 md:mr-4 shrink-0">
          {isOutdoorsyProperty ? (
            <BonfireAvatar
              defaultBrandIcon="General.Brandmark"
              ariaLabel={avatarName}
              imageUrl={photoUrl}
              size={EAvatarSize.Large}
            />
          ) : (
            <Avatar photoUrl={photoUrl} name={avatarName} size="160" className="rounded-[4px]" />
          )}
        </div>
      </div>

      <div className="mt-6 text-gray-500">
        <FormattedMessage
          defaultMessage="Host bio coming soon! Outdoorsy is committed to providing great stays for guests."
          id="R1HlQH"
        />
      </div>

      <div className="flex mt-6">
        <Button
          href={`mailto:${email}`}
          variant={ButtonVariants.primaryOutlined}
          size={ButtonSize.xxsmall}>
          <FormattedMessage defaultMessage="Email host" id="MoWiAL" />
        </Button>
      </div>
    </div>
  );
};
