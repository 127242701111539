import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import BillModuleMessage, {
  EBillModuleMessageType,
} from '@/components/ui/BillModule/BillModuleMessage/BillModuleMessage';
import { getIsUnavailable } from '@/redux/selectors/listing/availability';
import {
  getDiscountMessage,
  getIsRequestLessThanMinimumDays,
  getOwnerMinDays,
  getPricingInformation,
  getQuoteFromAndTo,
} from '@/redux/selectors/listing/bill';
import getHost from '@/redux/selectors/listing/host';
import { getListingMinimumDays } from '@/redux/selectors/listing/page';
import { getQuoteError } from '@/redux/selectors/quote';

import css from './BillSectionNotificationsContainer.module.css';

export const BillSectionNotificationsContainer: React.FC = () => {
  const intl = useIntl();

  const { from, to } = useSelector(getQuoteFromAndTo);

  const quoteError = useSelector(getQuoteError);
  const isUnavailable = useSelector(getIsUnavailable);
  const minimumDays = useSelector(getListingMinimumDays);
  const ownerMinimumDays = useSelector(getOwnerMinDays);
  const isRequestLessThanMinimumDays = useSelector(getIsRequestLessThanMinimumDays);
  const discountMessage = useSelector(getDiscountMessage);
  const hasDatesSelected = Boolean(from && to);
  const price = useSelector(getPricingInformation);
  const host = useSelector(getHost);
  const ownerName = host?.name;

  const errorMessage =
    quoteError ||
    (isUnavailable
      ? intl.formatMessage({
          defaultMessage: 'Your dates are not available',
          id: 'l7UBMY',
          description: 'Listing Page > Bill Module',
        })
      : undefined);

  const minimumDaysWarning = useMemo(() => {
    if (!minimumDays) {
      return undefined;
    }

    const numberOfDays = dayjs(to).diff(dayjs(from), 'day');
    const difference = Number(minimumDays - numberOfDays);

    if (difference > 0 && to && from && !isUnavailable) {
      return intl.formatMessage(
        {
          defaultMessage: `This rental has a {minimumDays} {priceType, select,
              day {day}
              other {night}
            } minimum. You will be charged for {difference} additional {priceType, select,
              day {{difference, plural, one {day} other {days}}}
              other {{difference, plural, one {night} other {nights}}}
            } if you proceed.`,
          id: 'zf7hge',
          description: 'Listing Page > Bill Module Container > Minimum days warning (long).',
        },
        { minimumDays, difference, priceType: price.period },
      );
    }

    return undefined;
  }, [from, to, isUnavailable, minimumDays, price, intl]);

  const requestLessThanMinimumDaysWarning = useMemo(() => {
    if (!ownerMinimumDays) {
      return undefined;
    }

    return intl.formatMessage(
      {
        defaultMessage: `This RV usually has a {ownerMinimumDays} {priceType, select,
            day {day}
            other {night}
          } minimum but {ownerName} is open to a shorter trip. Send a request to see what they say.`,
        id: 'YYREE2',
        description:
          'Listing Page > Bill Module Container > Request less than minimum days warning (long).',
      },
      { ownerMinimumDays, priceType: price.period, ownerName },
    );
  }, [ownerMinimumDays, price, ownerName, intl]);

  const shouldShowWarning = Boolean(
    minimumDays && hasDatesSelected && minimumDaysWarning && !isRequestLessThanMinimumDays,
  );

  const shouldShowRequestLessThanMinimumDaysWarning = Boolean(
    isRequestLessThanMinimumDays && requestLessThanMinimumDaysWarning,
  );

  return (
    <>
      {!!errorMessage && (
        <BillModuleMessage
          message={errorMessage}
          type={EBillModuleMessageType.critical}
          className={css.message}
        />
      )}
      {shouldShowWarning && (
        <BillModuleMessage
          message={minimumDaysWarning || ''}
          type={EBillModuleMessageType.critical}
          className={css.message}
        />
      )}
      {shouldShowRequestLessThanMinimumDaysWarning && (
        <BillModuleMessage
          message={requestLessThanMinimumDaysWarning || ''}
          type={EBillModuleMessageType.info}
          className={css.message}
        />
      )}
      {!shouldShowWarning && !shouldShowRequestLessThanMinimumDaysWarning && discountMessage && (
        <BillModuleMessage
          message={discountMessage || ''}
          type={EBillModuleMessageType.info}
          className={css.message}
        />
      )}
    </>
  );
};
