import React from 'react';
import { useSelector } from 'react-redux';

import { PriceSize } from '@/components/switchback/Price';
import { PriceDetails } from '@/components/ui/ListingTile/PriceDetails';
import { getPricingInformation } from '@/redux/selectors/listing/bill';
import { getGallery } from '@/redux/selectors/listing/gallery';
import { getIsCampground, getListingHeader } from '@/redux/selectors/listing/listingHeader';

import BillSectionMobileHeader from '../../BillSection/BillSectionMobileHeader';

export const BillSectionHeaderContainer: React.FC = () => {
  const price = useSelector(getPricingInformation);
  const listingHeader = useSelector(getListingHeader);
  const images = useSelector(getGallery);
  const isCampground = useSelector(getIsCampground);
  const primaryImage = images?.length ? images.find(item => item.primary) || images[0] : undefined;
  const thumbnail = primaryImage && { src: primaryImage.url, alt: '' };
  const { originalPrice, overrideDayPrice = 0 } = price;
  const originalPriceValue = (isCampground && overrideDayPrice) || originalPrice;

  return (
    <>
      <BillSectionMobileHeader title={listingHeader?.title || ''} image={thumbnail}>
        <PriceDetails {...price} showDiscount originalPrice={originalPriceValue} />
      </BillSectionMobileHeader>
      <div className="hidden lg:block">
        <PriceDetails
          {...price}
          showDiscount
          originalPrice={originalPriceValue}
          size={PriceSize.medium}
          isBillSection
        />
      </div>
    </>
  );
};
