import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@/components/switchback/Heading/Heading';
import Text from '@/components/switchback/Text/Text';
import { getNearbyRentalsURL } from '@/redux/selectors/stayStates';

const BillSectionUnpublished: React.FC = () => {
  const intl = useIntl();
  const href = useSelector(getNearbyRentalsURL);

  return (
    <div data-testid="unpublished-bill">
      <Heading level={2} className="mb-3 highlight autoType800">
        <FormattedMessage
          defaultMessage="Oops, this listing isn't active right now."
          id="vS8dFg"
          description="Listing Page > Bill Module"
        />
      </Heading>

      <Text type="paragraph" className="mb-8 text autoType300">
        <FormattedMessage
          defaultMessage="Let's find you something else."
          id="TmMrol"
          description="Listing Page > Bill Module"
        />
      </Text>

      <Button
        href={href}
        variant={EButtonColorVariant.Primary}
        label={intl.formatMessage({ defaultMessage: 'Explore other options', id: 'f0m/Sl' })}
        fullWidth
      />
    </div>
  );
};

export default BillSectionUnpublished;
