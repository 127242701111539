import he from 'he';
import Head from 'next/head';
import React from 'react';
import { useSelector } from 'react-redux';

import { PAGE_TITLE_SUFFIX } from '@/constants/pages';
import {
  getListingAlternatePaths,
  getListingCanonicalURL,
  getListingData,
  getListingDescription,
  getListingMetaProperties,
  getListingStructuredAccommodation,
  getListingStructuredBreadbrumbs,
  getListingStructuredProduct,
  getListingTitle,
} from '@/redux/selectors/listing/page';

const ListingHead: React.FC = () => {
  const title = useSelector(getListingTitle);
  const description = useSelector(getListingDescription);
  const alternate = useSelector(getListingAlternatePaths);
  const canonicalURL = useSelector(getListingCanonicalURL);
  const metaProperties = useSelector(getListingMetaProperties);
  const product = useSelector(getListingStructuredProduct);
  const accommodation = useSelector(getListingStructuredAccommodation);
  const breadcrumbs = useSelector(getListingStructuredBreadbrumbs);
  const listing = useSelector(getListingData);

  return (
    <Head>
      <title>{title ? `${he.decode(title)}${PAGE_TITLE_SUFFIX}` : 'Outdoorsy'}</title>
      <meta name="description" content={description} />
      {!listing?.has_been_published && <meta name="robots" content="none" />}
      {metaProperties
        .filter(tag => tag.content)
        .map(({ property, content }) => (
          <meta key={property} property={property} content={content} />
        ))}

      <link rel="canonical" href={canonicalURL} />
      {alternate?.map(({ locale, href }) => (
        <link key={locale} rel="alternate" href={href} hrefLang={locale} />
      ))}

      <script
        type="application/ld+json"
        data-tests-sd-product
        dangerouslySetInnerHTML={{ __html: product }}
      />

      <script
        type="application/ld+json"
        data-tests-sd-accommodation
        dangerouslySetInnerHTML={{ __html: accommodation }}
      />

      <script
        type="application/ld+json"
        data-tests-sd-breadcrumbs
        dangerouslySetInnerHTML={{ __html: breadcrumbs }}
      />
    </Head>
  );
};

export default ListingHead;
