import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Heading from '@/components/switchback/Heading/Heading';
import Help from '@/components/switchback/Help/Help';
import { IconType } from '@/components/switchback/Icon/IconComponent';
import { EVehicleDetailCategory, EVehicleDetails } from '@/constants/vehicleDetails';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IVehicleDetailsItem as IVehicleItem } from '@/redux/selectors/listing/rentalDetails';

import ClampModal from '../ClampModal/ClampModal';
import Feature from './FeatureItem';

interface IVehicleDetailsItem extends IVehicleItem {
  id: EVehicleDetails;
  icon: IconType;
}
interface IFeatures {
  details: IVehicleDetailsItem[];
  vehicleType: string;
}

const Features: React.FC<IFeatures> = ({ details, vehicleType }) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();

  const showMoreText = intl.formatMessage({
    defaultMessage: 'Show all features',
    id: '4TbjyJ',
  });

  const modalTitle = intl.formatMessage({
    defaultMessage: 'Features',
    id: 'ZXp0z1',
  });

  return (
    <>
      <div className="mb-4 md:mb-6">
        <Heading level={4} className="font-bold md:h3 text-700 semiHighlight">
          <FormattedMessage defaultMessage="Features" id="ZXp0z1" />
        </Heading>
        {vehicleType && (
          <div className="mt-0 md:mt-1 md:hidden">
            <span className="text-base semiHighlight text-secondary-base">{vehicleType}</span>
          </div>
        )}
      </div>
      <ClampModal
        showMoreText={showMoreText}
        isList
        hasMore={details.length > (isMobile ? 5 : 6)}
        modalContent={<ModalContent vehicleType={vehicleType} details={details} />}
        modalTitle={modalTitle}>
        <ul className="md:columns-2" data-testid="vehicle-details-list">
          {details.slice(0, isMobile ? 5 : 6).map(item => (
            <li
              key={item.id}
              className="mb-4 text-base font-normal text-primary-base semiHighlight"
              data-testid={`listing-vehicle-details-${item.id}`}>
              <Feature icon={item.icon} label={item.label} tooltip={item.description} />
            </li>
          ))}
        </ul>
      </ClampModal>
    </>
  );
};

export default Features;

interface IModalContentProps {
  vehicleType: string;
  details: IVehicleDetailsItem[];
}

const ModalContent: React.FC<IModalContentProps> = ({ vehicleType, details }) => {
  const generalItems = details.filter(item => item.category === EVehicleDetailCategory.GENERAL);
  const featuredItems = details.filter(item => item.category === EVehicleDetailCategory.DRIVABLE);

  return (
    <div>
      {generalItems.length > 0 && (
        <>
          <Heading level={3} className="pb-2 mb-2 text-xl font-medium semiHighlight">
            <FormattedMessage defaultMessage="General" id="1iEPTM" />
          </Heading>
          <div>
            <div className="py-4 text-base border-b border-gray-200 text-primary-base semiHighlight">
              <FormattedMessage
                defaultMessage="Year, make, model: {vehicleType}"
                id="HZU4SM"
                values={{ vehicleType }}
              />
            </div>
            <ul data-testid="vehicle-details-list">
              {generalItems.map(item => (
                <li
                  key={item.id}
                  className="py-4 text-base border-b border-gray-200 text-primary-base semiHighlight last:mb-5"
                  data-testid={`listing-vehicle-details-${item.id}`}>
                  {item.modalLabel || item.label}
                  {item.description && (
                    <Help className="ml-1.5" label={item.label || ''} content={item.description} />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      {featuredItems.length > 0 && (
        <>
          <Heading level={3} className="pb-2 mt-8 mb-2 text-xl font-medium semiHighlight">
            <FormattedMessage defaultMessage="Drivable" id="AZvTFz" />
          </Heading>
          <ul data-testid="vehicle-details-list">
            {featuredItems.map(item => (
              <li
                key={item.id}
                className="items-center py-4 text-base border-b border-gray-200 md:flex text-primary-base semiHighlight last:mb-5"
                data-testid={`listing-vehicle-details-${item.id}`}>
                {item.modalLabel || item.label}
                {item.description && (
                  <Help
                    className="hidden ml-1.5 md:block"
                    label={item.label || ''}
                    content={item.description}
                  />
                )}
                {item.description && (
                  <span className="block mt-1 text-sm md:hidden text-secondary-base">
                    {item.description}
                  </span>
                )}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
