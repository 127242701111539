import React from 'react';
import { useSelector } from 'react-redux';

import { getIsCampsite } from '@/redux/selectors/listing/campsite';
import {
  getCampsiteCampgroundRules,
  getCampsiteHouseRules,
} from '@/redux/selectors/listing/houseRules';

import { CampgroundRules } from './CampgroundRules';

export const CampgroundRulesContainer = () => {
  const isCampsite = useSelector(getIsCampsite);
  const checkInRules = useSelector(getCampsiteHouseRules);
  const campgroundRules = useSelector(getCampsiteCampgroundRules);

  if (!isCampsite) return null;

  return (
    <CampgroundRules checkInRules={checkInRules || undefined} campgroundRules={campgroundRules} />
  );
};
