import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { getIsUnavailable } from '@/redux/selectors/listing/availability';
import { getIsInstantBook } from '@/redux/selectors/listing/bill';
import { getQuoteError, getQuoteIsInstantBook } from '@/redux/selectors/quote';
import { getIntl } from '@/utility/i18n';

export const getCallToAction = createSelector<
  TRootState,
  TRootState['queryParams'],
  ReturnType<typeof getQuoteError>,
  ReturnType<typeof getIsUnavailable>,
  ReturnType<typeof getIsInstantBook>,
  ReturnType<typeof getQuoteIsInstantBook>,
  string
>(
  state => state.queryParams,
  getQuoteError,
  getIsUnavailable,
  getIsInstantBook,
  getQuoteIsInstantBook,
  (queryParams, quoteError, isUnavailable, isInstantBook, quoteIsInstantBook) => {
    const intl = getIntl();
    const { from, to, quote } = queryParams;

    if ((!from || !to) && !quote) {
      return intl.formatMessage({
        defaultMessage: 'Check availability',
        id: '6h+4pN',
      });
    }

    if (isUnavailable || quoteError) {
      return intl.formatMessage({
        defaultMessage: 'Change dates',
        id: 'OVEAi9',
      });
    }

    if (isInstantBook && quoteIsInstantBook) {
      return intl.formatMessage({ defaultMessage: 'Instant book', id: 'jC5Ueq' });
    }

    return intl.formatMessage({ defaultMessage: 'Review request', id: 'MKR5Bp' });
  },
);
