import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import Avatar from '@/components/switchback/Avatar/Avatar';
import ClampedText from '@/components/switchback/ClampedText/ClampedText';
import Heading from '@/components/switchback/Heading/Heading';
import { AWARD } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Tag, { TagColors, TagSizes, TagWeight } from '@/components/switchback/Tag/Tag';
import formatResponseTime from '@/utility/formatResponseTime';

import css from './HostBio.module.css';

type IHostBioElement = React.HTMLAttributes<HTMLDivElement>;

interface IProps {
  avgResponseTime?: number;
  children: string;
  cta?: ReactElement;
  hostProfileUrl?: string;
  isAllStar?: boolean;
  isDealer?: boolean;
  memberSince?: number;
  name?: string;
  photoUrl?: string;
  avatarName?: string;
  loading?: boolean;
}

const HostBio: React.FC<IProps & IHostBioElement> = ({
  avgResponseTime,
  children,
  cta,
  hostProfileUrl,
  isAllStar = false,
  isDealer = false,
  memberSince,
  name,
  photoUrl,
  avatarName,
  loading = false,
}) => {
  const intl = useIntl();

  const avatarAriaLabel = intl.formatMessage(
    {
      defaultMessage: "{name}'s profile page",
      id: 'uVqRsA',
    },
    { name },
  );

  const allStarMessage = intl.formatMessage({
    defaultMessage: 'All Star Host',
    id: 'LDKqJf',
  });

  const memberSinceComponent = (
    <p
      className={`self-start text-xs text-gray-500 md:justify-self-center md:transform md:whitespace-nowrap`}>
      {memberSince &&
        intl.formatMessage(
          {
            defaultMessage: 'Member since {year}',
            id: 'e404GO',
          },
          { year: memberSince },
        )}
      {memberSince && isDealer && <span aria-hidden>・</span>}
      {isDealer &&
        intl.formatMessage({
          defaultMessage: 'Dealer',
          id: 'xbovKX',
        })}
    </p>
  );

  const heading = (
    <>
      {isAllStar && (
        <div
          className="flex items-center uppercase gap-1 text-100 md:hidden"
          data-testid="meet-host-all-star">
          <Icon width={12} height={12} name={AWARD} />
          {allStarMessage}
        </div>
      )}
      <Heading level={2} className={`text-700 md:text-800 highlight mb-1 md:my-0`}>
        {intl.formatMessage(
          {
            defaultMessage: 'Meet your host, {name}',
            id: 'FHzDh5',
          },
          { name },
        )}
      </Heading>
      <div className="block md:hidden">{memberSinceComponent}</div>
    </>
  );

  const footer = (
    <>
      {cta}
      {!!avgResponseTime ||
        (loading && (
          <p
            className={`${css.ctaContent} mt-2 text-xs text-center text-gray-500 md:mt-0 md:ml-4 md:text-left md:w-32`}>
            {avgResponseTime && formatResponseTime(avgResponseTime)}
          </p>
        ))}
    </>
  );

  return (
    <div data-loading={loading}>
      <div className="flex items-start justify-between md:justify-start md:items-center">
        <div className={`${css.title} mb-1 md:order-2`}>{heading}</div>

        <div className="w-20 h-20 ml-20 text-center md:order-1 md:h-12 md:w-12 md:ml-0 md:mr-4">
          <a href={hostProfileUrl} aria-label={avatarAriaLabel} className={css.avatar}>
            <Avatar photoUrl={photoUrl} name={avatarName} size="160" />
          </a>
          {(isAllStar || loading) && (
            <div className={`${css.tag} hidden -mt-5 md:block`} data-testid="meet-host-all-star">
              <Tag color={TagColors.yellow} weight={TagWeight.bold} size={TagSizes.large}>
                <Icon aria-hidden width={12} height={12} name={AWARD} />
                {allStarMessage}
              </Tag>
            </div>
          )}
        </div>
      </div>

      <div className={`${css.description} mb-4 text-gray-500 md:mb-0 mt-4 semiHighlight`}>
        <ClampedText clamp={[5, 4, 3]} paragraphClasses={() => 'mb-4'} readMore readLess>
          {children}
        </ClampedText>
      </div>

      <div
        className={`${css.footer} text-center text-gray-500 md:text-left md:flex md:items-center`}>
        {footer}
      </div>
    </div>
  );
};

export default HostBio;
