import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import router from '@/hooks/useRouter';
import { ownerInfoSelector } from '@/redux/modules/owner/selectors';
import { getIsUnavailable } from '@/redux/selectors/listing/availability';
import { getCheckoutURL, getIsInstantBook } from '@/redux/selectors/listing/bill';
import {
  getQuoteData,
  getQuoteError,
  getQuoteId,
  getQuoteInitialDuration,
  getQuoteIsInstantBook,
  getQuoteIsLoading,
} from '@/redux/selectors/quote';
import { trackDiscountPromotionAcceptedEvent } from '@/services/analytics/listings';

import BillSectionCurrencyNotificationContainer from '../../BillSection/BillSectionCurrencyNotificationContainer';

interface IBillModuleRequestBookStepsProps {
  canRequestToBook?: boolean;
  onRequestDatesChange: () => void;
}

const CallToActionText = ({ hasRequestedQuote }: { hasRequestedQuote: boolean }) => {
  const isUnavailable = useSelector(getIsUnavailable);
  const quoteError = useSelector(getQuoteError);
  const isInstantBook = useSelector(getIsInstantBook);
  const quoteIsInstantBook = useSelector(getQuoteIsInstantBook);

  if (!hasRequestedQuote) {
    return <FormattedMessage defaultMessage="Check availability" id="6h+4pN" />;
  }

  if (isUnavailable || quoteError) {
    return <FormattedMessage defaultMessage="Change dates" id="OVEAi9" />;
  }

  if (isInstantBook && quoteIsInstantBook) {
    return <FormattedMessage defaultMessage="Instant book" id="jC5Ueq" />;
  }

  return <FormattedMessage defaultMessage="Review request" id="MKR5Bp" />;
};

export const BillModuleRequestBook: React.FC<IBillModuleRequestBookStepsProps> = ({
  canRequestToBook = true,
  onRequestDatesChange,
}) => {
  const checkoutURL = useSelector(getCheckoutURL);
  const quoteIsLoading = useSelector(getQuoteIsLoading);
  const isUnavailable = useSelector(getIsUnavailable);
  const quoteError = useSelector(getQuoteError);
  const isInstantBook = useSelector(getIsInstantBook);
  const quote = useSelector(getQuoteData);
  const quoteInitialDuration = useSelector(getQuoteInitialDuration);
  const ownerInfo = useSelector(ownerInfoSelector);
  const isDealer = ownerInfo?.dealer || false;
  const hasRequestedQuote = !!useSelector(getQuoteId);
  const hasInvalidDates = isUnavailable || quoteError;
  const shouldRequestDatesChange = !hasRequestedQuote || hasInvalidDates;

  const handleClickCallToAction = () => {
    if (shouldRequestDatesChange) {
      onRequestDatesChange();
      return;
    }

    if (quote?.duration && quoteInitialDuration && quote.duration > quoteInitialDuration) {
      trackDiscountPromotionAcceptedEvent(quote, isDealer);
    }

    router.push(checkoutURL);
  };

  const getTextBelowCTA = () => {
    if (hasRequestedQuote && !hasInvalidDates && !isInstantBook) {
      return (
        <FormattedMessage
          defaultMessage="You won’t be charged yet"
          id="MfhswE"
          description="Listing Page > Bill Module > Calendar warning"
        />
      );
    }

    return null;
  };

  return (
    <>
      <div className="mt-auto lg:mt-0">
        <Button
          variant={EButtonColorVariant.Primary}
          label={<CallToActionText hasRequestedQuote={hasRequestedQuote} />}
          disabled={quoteIsLoading || (shouldRequestDatesChange ? false : !canRequestToBook)}
          data-testid="request-booking-btn"
          fullWidth
          onClick={handleClickCallToAction}
        />
      </div>

      <div className="mt-4 text-center text-gray-500 autoType300">{getTextBelowCTA()}</div>

      {hasRequestedQuote && <BillSectionCurrencyNotificationContainer />}
    </>
  );
};
