import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { BillModuleOccupancy } from '@/components/bill/BillModuleOccupancy';
import {
  BillModuleProtection,
  useBillModuleProtectionData,
} from '@/components/bill/BillModuleProtection';
import { BillModuleTotal } from '@/components/bill/BillModuleTotal/BillModuleTotal';
import { BillSectionStep } from '@/components/bill/BillSectionStep/BillSectionStep';
import { BillModuleStepButton } from '@/components/ui/BillModule/BillModuleStepButton/BillModuleStepButton';
import PayPalMessageProvider from '@/graphql/components/braintree/PayPalMessageProvider';
import { getBillSummary } from '@/redux/selectors/bill/summary';
import { getHasAddons } from '@/redux/selectors/listing/addons';
import { getIsCampsite, getIsStationaryCamperCampsite } from '@/redux/selectors/listing/campsite';
import { getDeliveryRadius } from '@/redux/selectors/listing/delivery';
import { getInsuranceBundles } from '@/redux/selectors/listing/insuranceBundles';
import { getIsStay } from '@/redux/selectors/listing/listingHeader';
import {
  getQuoteCurrency,
  getQuoteDelivery,
  getQuoteIsLoading,
  getQuoteIsSiblingQuote,
} from '@/redux/selectors/quote';

import { BillModuleAddOns } from '../BillModuleAddOns/BillModuleAddOns';
import { BillSectionDatesContainerSteps } from '../BillModuleDates/BillSectionDatesContainerSteps';
import { BillSectionDeliveryContainer } from '../BillModuleDelivery/BillSectionDeliveryContainer';
import { BillModuleInsurance } from '../BillModuleInsurance/BillModuleInsurance';
import { BillSectionNotificationsContainer } from '../BillModuleNotifications/BillSectionNotificationsContainer';
import { BillModuleRequestBook } from '../BillModuleRequestBook/BillModuleRequestBook';
import { BillSectionHeaderContainer } from '../BillSectionHeaderContainer/BillSectionHeaderContainer';
import { EQuoteStep, useBillSelectionStep } from './useBillSelectionStep';

const DisabledStep = (props: { text: React.ReactNode; required?: boolean }) => (
  <BillModuleStepButton outlined={false} disabled {...props} />
);

export const BillSelectionStep = () => {
  const quoteIsLoading = useSelector(getQuoteIsLoading);
  const deliveryRadius = useSelector(getDeliveryRadius);
  const quoteDelivery = useSelector(getQuoteDelivery);
  const currency = useSelector(getQuoteCurrency);
  const insuranceBundles = useSelector(getInsuranceBundles);
  const summary = useSelector(getBillSummary);
  const hasAddons = useSelector(getHasAddons);
  const isCampsite = useSelector(getIsCampsite);
  const isStationaryCamperCampsite = useSelector(getIsStationaryCamperCampsite);
  const isStay = useSelector(getIsStay);
  const isSiblingQuote = useSelector(getQuoteIsSiblingQuote);

  const showFulfillmentStep = !isCampsite && !isStay;
  const hasDelivery = deliveryRadius && deliveryRadius.radius > 0;
  const shouldSkipFulfillment = !hasDelivery || !showFulfillmentStep || !!quoteDelivery?.location;

  const showInsuranceStep =
    (!isCampsite || isStationaryCamperCampsite) &&
    !isStay &&
    !summary?.isCustomInsurance &&
    (!summary || !!summary.insuranceValue || !!summary.waiverSummary);
  const shouldSkipInsurance = !showInsuranceStep || insuranceBundles.length <= 1;

  const {
    showProtectionStep,
    shouldSkipProtection,
    availableTripInsuranceService,
    addedTripInsuranceService,
    availableRoamlyWeatherInsuranceService,
    addedRoamlyWeatherInsuranceService,
  } = useBillModuleProtectionData();

  const {
    currentStep,
    moveToStep,
    moveToNextStepFrom,
    isCalendarOpened,
    handleOpenCalendar,
    handleDismissCalendar,
    datesSelection,
    handleConfirmDates,
    handleClearDates,
  } = useBillSelectionStep();

  const hasDatesSelected = Boolean(datesSelection?.from && datesSelection?.to);
  const canRequestToBook = currentStep === EQuoteStep.REQUEST;

  // It might happen we have to skip some steps.
  // Check one by one and skip all skippable steps at once.
  const nextStep = (() => {
    let possiblyNextStep = currentStep;

    // Skip pick up/delivery if no delivery is available
    if (possiblyNextStep === EQuoteStep.FULFILLMENT && shouldSkipFulfillment) {
      possiblyNextStep = possiblyNextStep + 1;
    }

    // Skip insurance selection when stationary
    if (possiblyNextStep === EQuoteStep.INSURANCE && shouldSkipInsurance) {
      possiblyNextStep = possiblyNextStep + 1;
    }

    if (possiblyNextStep === EQuoteStep.PROTECTION && shouldSkipProtection) {
      possiblyNextStep = possiblyNextStep + 1;
    }

    // don't require occupancy step
    // it is auto-filled either from user input (query params)
    // or with default values during quote creation
    if (possiblyNextStep === EQuoteStep.OCCUPANCY) {
      possiblyNextStep = possiblyNextStep + 1;
    }

    return possiblyNextStep;
  })();

  useEffect(() => {
    if (nextStep > currentStep) moveToStep(nextStep);
  }, [nextStep, currentStep, moveToStep]);

  return (
    <>
      <BillSectionHeaderContainer />

      <div>
        <BillSectionStep>
          <BillSectionDatesContainerSteps
            datesSelection={datesSelection}
            calendarIsOpened={isCalendarOpened}
            onOpenCalendar={handleOpenCalendar}
            onDismissCalendar={handleDismissCalendar}
            onConfirmDates={handleConfirmDates}
            onClearDates={handleClearDates}
          />

          {hasDatesSelected && !quoteIsLoading && <BillSectionNotificationsContainer />}
        </BillSectionStep>

        {showFulfillmentStep && (
          <BillSectionStep
            showDivider={showInsuranceStep || showProtectionStep || hasAddons || canRequestToBook}>
            {currentStep >= EQuoteStep.FULFILLMENT ? (
              <BillSectionDeliveryContainer
                updateStep={() => moveToNextStepFrom(EQuoteStep.FULFILLMENT)}
                isCompleted={currentStep > EQuoteStep.FULFILLMENT}
              />
            ) : (
              <DisabledStep
                text={<FormattedMessage defaultMessage="Getting the RV" id="17L9De" />}
              />
            )}
          </BillSectionStep>
        )}

        {(isCampsite || isSiblingQuote) && (
          <BillSectionStep
            showDivider={showInsuranceStep || showProtectionStep || hasAddons || canRequestToBook}>
            {currentStep >= EQuoteStep.OCCUPANCY ? (
              <BillModuleOccupancy
                onGuestsSelected={() => moveToNextStepFrom(EQuoteStep.OCCUPANCY)}
              />
            ) : (
              <DisabledStep text={<FormattedMessage defaultMessage="Guests" id="NIVTxd" />} />
            )}
          </BillSectionStep>
        )}

        {showInsuranceStep && (
          <BillSectionStep showDivider={showProtectionStep || hasAddons || canRequestToBook}>
            {currentStep >= EQuoteStep.INSURANCE ? (
              <BillModuleInsurance
                updateStep={() => moveToNextStepFrom(EQuoteStep.INSURANCE)}
                completed={currentStep > EQuoteStep.INSURANCE}
              />
            ) : (
              <DisabledStep
                text={<FormattedMessage defaultMessage="Insurance coverage" id="yAfzwK" />}
                required
              />
            )}
          </BillSectionStep>
        )}

        {showProtectionStep && (
          <BillSectionStep showDivider={isCampsite || hasAddons || canRequestToBook}>
            {currentStep >= EQuoteStep.PROTECTION ? (
              <BillModuleProtection
                expanded={currentStep === EQuoteStep.PROTECTION}
                onTripProtectionSelected={() => moveToNextStepFrom(EQuoteStep.PROTECTION)}
                availableTripInsuranceService={availableTripInsuranceService}
                addedTripInsuranceService={addedTripInsuranceService}
                availableRoamlyWeatherInsuranceService={availableRoamlyWeatherInsuranceService}
                addedRoamlyWeatherInsuranceService={addedRoamlyWeatherInsuranceService}
              />
            ) : (
              <DisabledStep
                text={<FormattedMessage defaultMessage="Trip protection" id="Si9jYU" />}
              />
            )}
          </BillSectionStep>
        )}

        {!isSiblingQuote && hasAddons && (
          <BillSectionStep showDivider={isCampsite || canRequestToBook}>
            {currentStep >= EQuoteStep.ADDON ? (
              <BillModuleAddOns
                updateStep={() => moveToNextStepFrom(EQuoteStep.ADDON)}
                completed={currentStep > EQuoteStep.ADDON}
              />
            ) : (
              <DisabledStep text={<FormattedMessage defaultMessage="Add-ons" id="5lP56d" />} />
            )}
          </BillSectionStep>
        )}

        {canRequestToBook && (
          <PayPalMessageProvider currency={currency}>
            <BillModuleTotal />
          </PayPalMessageProvider>
        )}
      </div>

      <BillModuleRequestBook
        canRequestToBook={canRequestToBook}
        onRequestDatesChange={handleOpenCalendar}
      />
    </>
  );
};
