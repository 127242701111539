import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import {
  getQuoteDiscountPercentOff,
  getQuoteEventData,
  getQuoteOriginalDayPrice,
  IQuoteEventData,
} from '@/redux/selectors/listing/bill';
import {
  getListingEventData,
  getListingId,
  IListingEventData,
} from '@/redux/selectors/listing/page';
import { IBookingRequested } from '@/services/analytics/bookings/types';

export const getBookingRequestedEventData = createSelector<
  TRootState,
  number | undefined,
  IListingEventData,
  IQuoteEventData,
  number | undefined,
  number | undefined,
  IBookingRequested
>(
  getListingId,
  getListingEventData,
  getQuoteEventData,
  getQuoteOriginalDayPrice,
  getQuoteDiscountPercentOff,
  (rentalId, listingEventData, quoteData, originalPricePerDay, discountPercentOff) => {
    const from = new Date(String(listingEventData.from || quoteData.from));
    const to = new Date(String(listingEventData.to || quoteData.to));
    const areDatesPresent = !isNaN(from.getTime()) && !isNaN(to.getTime());
    const numberOfDays = areDatesPresent ? dayjs(to).diff(dayjs(from), 'day') : 0;
    const pricePerNight = quoteData.price_per_night || listingEventData.price;

    return {
      rentalID: rentalId,
      isInstabook: listingEventData.instant_book || false,
      totalCost: areDatesPresent ? quoteData.total : null,
      departureDate: parseInt((from.getTime() / 1000).toFixed(0)),
      returnDate: parseInt((to.getTime() / 1000).toFixed(0)),
      pricePerNight: pricePerNight,
      basePricePerNight: areDatesPresent ? originalPricePerDay : pricePerNight,
      discountPercentOff: discountPercentOff,
      listingUrl: listingEventData.url,
      rvImageUrl: listingEventData.image_url,
      rvName: listingEventData.name,
      totalNights: areDatesPresent ? numberOfDays : null,
      ...(listingEventData.isCampground && {
        odnID: listingEventData.odnID,
        campgroundAddress: listingEventData.campgroundAddress,
        campgroundCheckInTime: listingEventData.campgroundCheckInTime,
        campgroundCheckOutTime: listingEventData.campgroundCheckOutTime,
        campgroundName: listingEventData.campgroundName,
        displaySiteCategoryType: listingEventData.displaySiteCategoryType,
        displaySiteType: listingEventData.displaySiteType,
        siteCategoryType: listingEventData.siteCategoryType,
        ...(listingEventData.siteCategoryType === 'lodging_site' && {
          stayType: listingEventData.stayType,
        }),
        isOutdoorsyDestination: listingEventData.isOutdoorsyDestination,
      }),
      isOutdoorsyStay: !!quoteData.isOutdoorsyStay,
    } as IBookingRequested;
  },
);
