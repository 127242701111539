import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import { IQuoteDelivery } from '@/redux/modules/quote';
import { TRootState } from '@/redux/rootReducer';
import { getBookingRequestedEventData } from '@/redux/selectors/analytics-selectors/booking/bookingRequestedEventData';
import { getIsCampground, getIsStay } from '@/redux/selectors/listing/listingHeader';
import {
  getAverageReviewsRatings,
  getListingEventData,
  getListingReviewsNum,
  getListingScore,
  IListingEventData,
} from '@/redux/selectors/listing/page';
import { getFlexibleDateRangeSelected } from '@/redux/selectors/queryParams';
import { getQuoteDelivery } from '@/redux/selectors/quote';
import { IBookingRequested } from '@/services/analytics/bookings/types';
import { IListingEvent } from '@/services/analytics/listings/types';
import { TFlexibleDateRange } from '@/services/analytics/search/types';
import { ERentalType } from '@/services/analytics/types';
import { IAverageReviews } from '@/services/types/search/rentals/id';

export const getListingViewedEventData = createSelector<
  TRootState,
  IBookingRequested,
  number | undefined,
  number | undefined,
  TFlexibleDateRange,
  IQuoteDelivery | undefined,
  IAverageReviews | undefined,
  boolean,
  boolean,
  IListingEventData | undefined,
  IListingEvent | null
>(
  getBookingRequestedEventData,
  getListingScore,
  getListingReviewsNum,
  getFlexibleDateRangeSelected,
  getQuoteDelivery,
  getAverageReviewsRatings,
  getIsStay,
  getIsCampground,
  getListingEventData,
  (
    bookingData,
    listingScore,
    listingReviewsNum,
    flexibleDateRange,
    quoteDelivery,
    listingAverageRatings,
    isStay,
    isCampground,
    listingEventData,
  ) => {
    if (bookingData) {
      const subcategoryReviews: Record<string, number> = {};
      const ownerReviews: Record<string, number> = {};
      const rentalTypeProps = (() => {
        if (isCampground) {
          return { rentalType: ERentalType.CAMPGROUND };
        }

        if (isStay) {
          return { rentalType: ERentalType.STAY };
        }

        return {
          rentalType: ERentalType.RV,
          handoffMethod: quoteDelivery?.stationary ? 'pickup' : 'delivery',
        };
      })();

      if (listingAverageRatings?.rental) {
        listingAverageRatings?.rental.forEach(rev => {
          subcategoryReviews[rev.name] = rev.score;
        });
      }

      if (listingAverageRatings?.owner) {
        listingAverageRatings?.owner.forEach(rev => {
          ownerReviews[rev.name] = rev.score;
        });
      }

      return {
        ...bookingData,
        reviewsCount: listingReviewsNum || 0,
        reviewsAverage: listingScore || 0,
        flexibleDateRange,
        subcategoryReviews: isEmpty(subcategoryReviews) ? null : subcategoryReviews,
        ownerReviews: isEmpty(ownerReviews) ? null : ownerReviews,
        source: 'search_listing',
        ...rentalTypeProps,
        isCampground,
        ...(isCampground && {
          displaySiteCategoryType: listingEventData?.displaySiteCategoryType,
          siteCategoryType: listingEventData?.siteCategoryType,
        }),
      } as IListingEvent;
    }

    return null;
  },
);
