import { ETextStyleVariant, Icon, RawButton, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useDeliveryModalContext } from '@/context/DeliveryModalContext';

export const OutdoorsyStaysBanner = () => {
  const { openSetupDeliveryModal } = useDeliveryModalContext();
  return (
    <RawButton
      className="flex items-center justify-between w-full px-6 py-4 mb-6 text-white rounded-lg gap-2 md:gap-4 bg-[linear-gradient(270deg,#536D67,#284941)]"
      onClick={openSetupDeliveryModal}>
      <div className="flex items-center gap-3 md:gap-4">
        <span className="flex items-center justify-center w-10 h-10 rounded-full min-w-[40px] bg-red-301 text-primary-base">
          <Icon name="Activities.Activities.Camping" width={24} height={24} />
        </span>
        <Text variant={ETextStyleVariant.MediumRegular} className="text-left">
          <FormattedMessage
            defaultMessage="Get this RV set up for you at our network of campgrounds."
            id="H2AkHg"
          />
        </Text>
      </div>
      <span>
        <Icon name="General.Caret.Right" width={24} height={24} />
      </span>
    </RawButton>
  );
};
