import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Heading from '@/components/switchback/Heading/Heading';
import Text from '@/components/switchback/Text/Text';
import { listingPageAnchors } from '@/constants/anchors';
import { IAddOn } from '@/redux/selectors/listing/addons';

import ClampModal from '../ClampModal/ClampModal';
import AddOn from './AddOn';

type IAddOnsSectionElement = React.HTMLAttributes<HTMLElement>;
interface IProps {
  loading?: boolean;
  addons: IAddOn[];
}

const AddOnsSection: React.FC<IProps & IAddOnsSectionElement> = ({ loading, addons }) => {
  const intl = useIntl();
  const showMore = intl.formatMessage(
    {
      defaultMessage: 'Show all {value} add-ons',
      id: 'iao+Mc',
    },
    { value: addons.length },
  );
  return (
    <section id={listingPageAnchors.ADDONS}>
      <Heading level={2} className="text-2xl highlight">
        <FormattedMessage defaultMessage="Add-ons" id="5lP56d" />
      </Heading>
      <Text type="paragraph" className="mb-6 text-base semiHighlight">
        <FormattedMessage
          defaultMessage="Enhance your trip with these add-ons. Available at checkout."
          id="SX6zi8"
        />
      </Text>

      <ClampModal
        showMoreText={showMore}
        modalTitle="Add-ons"
        hasMore={addons.length > 3}
        isList
        modalContent={<ModalContent addons={addons} />}>
        <div className={loading ? 'pointer-events-none' : ''}>
          {addons.slice(0, 3).map(addon => (
            <AddOn addon={addon} key={addon.id} />
          ))}
        </div>
      </ClampModal>
    </section>
  );
};

export default AddOnsSection;

interface IModalContentProps {
  addons: IAddOn[];
}

const ModalContent: React.FC<IModalContentProps> = ({ addons }) => (
  <div>
    {addons.map(addon => (
      <AddOn addon={addon} key={addon.id} isModal />
    ))}
  </div>
);
