import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import ClampedText from '@/components/switchback/ClampedText/ClampedText';
import Divider from '@/components/switchback/Divider/Divider';
import Heading from '@/components/switchback/Heading/Heading';
import { listingPageAnchors } from '@/constants/anchors';
import { ECancellationPolicies } from '@/constants/houseRules';
import CancellationPolicy from '@/graphql/components/route/listing/HouseRules/CancellationPolicy';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { IUsageRules } from '@/redux/selectors/listing/houseRules';
import { getQuoteData } from '@/redux/selectors/quote';

import FeatureItem from '../Features/FeatureItem';
import classes from './HouseRules.module.css';
import { IOwnerRule } from './HouseRulesContainer';
import RateDetails from './RateDetails/RateDetails';

interface IHouseRulesProps {
  className?: string;
  ownerRules?: IOwnerRule[];
  houseRulesText: string;
  generatorRules?: IUsageRules;
  parkingRules?: IUsageRules;
  stayCheckinRules?: IUsageRules[];
  mileageRules?: IUsageRules;
  cancelationPolicy?: ECancellationPolicies;
  isStay?: boolean;
  rentalId?: number;
}

const HouseRules: React.FC<IHouseRulesProps> = ({
  className,
  ownerRules,
  houseRulesText,
  generatorRules,
  mileageRules,
  cancelationPolicy,
  stayCheckinRules,
  parkingRules,
  isStay,
  rentalId,
}) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();
  const quote = useSelector(getQuoteData);
  const showModeratePolicyNote =
    !!quote?.deliverable_campgrounds?.length &&
    !quote.sibling_quote_group_id &&
    cancelationPolicy !== ECancellationPolicies.MODERATE;
  const hasSiblingQuote = !!quote?.sibling_quote_group_id;

  return (
    <div className={className} id={listingPageAnchors.RULES}>
      <Heading level={3} className="mb-5 font-bold md:mb-8 md:h3 text-700 semiHighlight">
        <FormattedMessage defaultMessage="Rules and policies" id="mcwwlw" />
      </Heading>
      <div className="mb-6 md:flex">
        {isStay ? (
          <ul className={classes.rules}>
            {stayCheckinRules?.map(item => (
              <li key={item.title}>
                <FeatureItem icon={item.icon} label={item.title} />
              </li>
            ))}
          </ul>
        ) : (
          ownerRules &&
          ownerRules.length > 0 && (
            <ul className={classes.rules}>
              {ownerRules
                .sort((a, b) => (a.allowed === b.allowed ? 0 : a.allowed ? -1 : 1))
                .map(item => (
                  <li key={item.label.id}>
                    <FeatureItem icon={item.icon} label={intl.formatMessage(item.label)} />
                  </li>
                ))}
            </ul>
          )
        )}
        {isStay ? (
          <ul className={classes.rules}>
            {parkingRules && (
              <li>
                <FeatureItem icon={parkingRules.icon} label={parkingRules.title} />
              </li>
            )}
            {ownerRules && ownerRules[1] && (
              <li key={ownerRules[1]?.label.id}>
                <FeatureItem
                  icon={ownerRules[1].icon}
                  label={intl.formatMessage(ownerRules[1].label)}
                />
              </li>
            )}
            {ownerRules && ownerRules[0] && (
              <li key={ownerRules[0]?.label.id}>
                <FeatureItem
                  icon={ownerRules[0].icon}
                  label={intl.formatMessage(ownerRules[0].label)}
                />
              </li>
            )}
          </ul>
        ) : (
          (mileageRules || generatorRules) && (
            <ul className={classes.rules}>
              {mileageRules && !isStay && (
                <li>
                  <FeatureItem
                    icon={mileageRules.icon}
                    label={mileageRules.title}
                    tooltip={mileageRules.tooltip}
                  />
                </li>
              )}
              {generatorRules && !isStay && (
                <li>
                  <FeatureItem
                    icon={generatorRules.icon}
                    label={generatorRules.title}
                    tooltip={generatorRules.tooltip}
                  />
                </li>
              )}
            </ul>
          )
        )}
      </div>
      {houseRulesText && (
        <div className="semiHighlight">
          {isMobile ? (
            <div className={classes.rulesContent}>
              <ReactMarkdown>{houseRulesText}</ReactMarkdown>
            </div>
          ) : (
            <ClampedText
              paragraphClasses="text-base text-primary-base"
              clamp={3}
              readMore={true}
              readLess={true}>
              {houseRulesText}
            </ClampedText>
          )}
        </div>
      )}
      {!isStay && <RateDetails />}
      {cancelationPolicy && (
        <>
          <Divider className="my-6" />
          <CancellationPolicy
            policy={cancelationPolicy}
            rentalId={rentalId}
            showModeratePolicyNote={showModeratePolicyNote}
            hasSiblingQuote={hasSiblingQuote}
          />
        </>
      )}
    </div>
  );
};

export default HouseRules;
