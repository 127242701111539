import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { SubmitErrorHandler, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FIELDS } from '@/components/route/listing/AskHostModal/AskHostModal';
import FeedbackScreen from '@/components/route/listing/FeedbackScreen/FeedbackScreen';
import Link from '@/components/switchback/Link/Link';
import Loading from '@/components/switchback/Loading/Loading';
import Text from '@/components/switchback/Text/Text';
import TextArea from '@/components/switchback/TextArea/TextArea';
import formatResponseTime from '@/utility/formatResponseTime';

import AskHostFooter from '../AskHostFooter/AskHostFooter';
import { FormData } from '../AskHostModal/AskHostModalContainer';
import css from './AskHostForm.module.css';

enum feedbackScreenType {
  success = 'success',
  error = 'error',
}

interface IProps extends Omit<React.HTMLAttributes<HTMLElement>, 'onError'> {
  isStay?: boolean;
  avgResponseTime: number;
  hasSucceeded?: boolean;
  error?: string;
  form: UseFormReturn<FormData>;
  formId: string;
  hostName: string;
  feedbackType?: feedbackScreenType;
  isAuthenticated: boolean;
  loading?: boolean;
  value: string;
  onSubmit: SubmitHandler<any>;
  onError?: SubmitErrorHandler<any>;
  onClickSignup: () => void;
  onClickLogin: () => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

type TSuggestionsProps = {
  isStay?: boolean;
};

const Suggestions: React.FC<TSuggestionsProps> = ({ isStay }) => {
  const intl = useIntl();

  let suggestions: string[];

  if (isStay) {
    suggestions = [
      intl.formatMessage({
        defaultMessage: 'How many people will be traveling with you',
        id: 'JmDqHh',
      }),
      intl.formatMessage({
        defaultMessage: 'The purpose of your trip',
        id: 'vPsePY',
      }),
    ];
  } else {
    suggestions = [
      intl.formatMessage({
        defaultMessage: 'If you have any driving experience',
        id: 'lU4y5M',
      }),
      intl.formatMessage({
        defaultMessage: 'How many people will be traveling with you',
        id: 'JmDqHh',
      }),
      intl.formatMessage({
        defaultMessage: "Where you'll be headed and for how long",
        id: 'M4ZaDI',
      }),
    ];
  }

  return (
    <ul className="mb-6">
      {suggestions.map(item => (
        <li key={item} className={`${css.suggestion}`}>
          {item}
        </li>
      ))}
    </ul>
  );
};

const AskHostInput: React.FC<Pick<IProps, 'form' | 'onChange' | 'value'>> = ({
  form,
  onChange,
  value,
}) => {
  const intl = useIntl();

  return (
    <TextArea
      error={form.formState.errors[FIELDS.MESSAGE]?.message}
      id={FIELDS.MESSAGE}
      label={intl.formatMessage({
        defaultMessage: 'Enter your message',
        id: 'dS0/IR',
      })}
      {...form.register(FIELDS.MESSAGE, {
        required: {
          value: true,
          message: intl.formatMessage({
            defaultMessage: 'Please enter a message',
            id: 'DKfBvr',
          }),
        },
        value,
        onChange,
      })}
      maxRow={5}
      minRow={5}
    />
  );
};

const AskHostAuthWall: React.FC<Pick<IProps, 'hostName' | 'onClickSignup' | 'onClickLogin'>> = ({
  hostName,
  onClickSignup,
  onClickLogin,
}) => {
  const intl = useIntl();
  const message = intl.formatMessage(
    {
      defaultMessage: 'You need an account to send {name} a message.',
      id: 'SBUGU+',
      description: 'Message shown at ask host modal when user is not authenticated.',
    },
    {
      name: hostName,
    },
  );
  const signupCta = intl.formatMessage({
    defaultMessage: 'Sign up',
    id: 'EcWYb0',
    description: 'CTA label at ask host modal when user is not authenticated.',
  });
  const loginMessage = intl.formatMessage({
    defaultMessage: 'Already have an account?',
    id: 'SOMm1X',
    description: 'Sign in message at ask host modal when user is not authenticated.',
  });
  const loginCta = intl.formatMessage({
    defaultMessage: 'Login',
    id: 'UWWGt9',
    description: 'Sign in cta at ask host modal when user is not authenticated.',
  });

  return (
    <div className="flex flex-col items-center justify-center py-8 border border-gray-200 rounded-md">
      {message}
      <Button
        className="mt-6"
        data-testid="sign-up-btn"
        variant={EButtonColorVariant.Primary}
        label={signupCta}
        onClick={onClickSignup}
        type="button"
      />
      <div className="flex mt-4">
        {loginMessage}{' '}
        <Link
          data-testid="login-btn"
          className="ml-1 autoType400"
          onClick={onClickLogin}
          type="button">
          {loginCta}
        </Link>
      </div>
    </div>
  );
};

const AskHostForm: React.FC<IProps> = ({
  isStay,
  avgResponseTime,
  form,
  formId,
  hasSucceeded,
  error,
  hostName,
  isAuthenticated,
  loading = false,
  onSubmit,
  onError,
  onClickSignup,
  onClickLogin,
  onChange,
  value,
}) => {
  const intl = useIntl();
  const buttonLabel = intl.formatMessage({
    defaultMessage: 'Send message',
    id: 'Xx0WZV',
  });

  return (
    <>
      <div className="z-10">
        {loading && <Loading />}
        {hasSucceeded !== undefined && (
          <FeedbackScreen
            title={
              hasSucceeded
                ? intl.formatMessage({
                    defaultMessage: 'Message sent',
                    id: 'OzIqxn',
                  })
                : intl.formatMessage({
                    defaultMessage: 'An error occurred',
                    id: 'ox304v',
                  })
            }
            description={
              hasSucceeded
                ? formatResponseTime(avgResponseTime, true)
                : error ||
                  intl.formatMessage({
                    defaultMessage: 'Please try again later',
                    id: 'aoWE20',
                  })
            }
          />
        )}
      </div>
      <form
        id={formId}
        onSubmit={form.handleSubmit(onSubmit, onError)}
        className={`autoType300 ${loading ? 'opacity-0' : ''}`}>
        {hasSucceeded ?? (
          <>
            <Text type="paragraph" className={'mb-1 font-bold'}>
              {intl.formatMessage({
                defaultMessage: 'When you introduce yourself we suggest you include:',
                id: 'AVUnIj',
              })}
            </Text>
            <Suggestions isStay={isStay} />
            {isAuthenticated ? (
              <AskHostInput form={form} value={value} onChange={onChange} />
            ) : (
              <AskHostAuthWall
                hostName={hostName}
                onClickSignup={onClickSignup}
                onClickLogin={onClickLogin}
              />
            )}
          </>
        )}
        {Boolean(isAuthenticated && !hasSucceeded && !error) && (
          <div className="flex flex-col items-center mt-6 md:flex-row">
            <AskHostFooter buttonLabel={buttonLabel} form={form} formId={formId} />
          </div>
        )}
      </form>
    </>
  );
};

export default AskHostForm;
