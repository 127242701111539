import { Button, ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Link from '@/components/switchback/Link/Link';
import { getListingData } from '@/redux/selectors/listing/page';

export const ViewCampgroundPage = () => {
  const intl = useIntl();
  const listingData = useSelector(getListingData);

  if (!listingData?.campground) return null;

  return (
    <div className="flex flex-col px-10 my-10 rounded-lg md:items-center md:flex-row md:justify-between py-7 bg-canvas-100 gap-10 md:gap-2">
      <div>
        <Text variant={ETextStyleVariant.LargeBold}>
          <FormattedMessage
            defaultMessage="For more information about {location}, view our campground page"
            id="o1MSJQ"
            values={{ location: listingData.campground.name }}
          />
        </Text>
      </div>

      <Link href={listingData.campground.slug} legacy forceClientsideRouting passHref>
        <Button
          target="_blank"
          label={intl.formatMessage({ defaultMessage: 'View page', id: 'Pr6MsE' })}
          className="w-full md:w-auto md:whitespace-nowrap"
        />
      </Link>
    </div>
  );
};
