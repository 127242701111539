import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AskOwnerButton from '@/components/route/listing/AskHostButton/AskHostButton';
import { openPhoneVerificationModal } from '@/redux/modules/auth';
import { closeAskHostModal, openAskOwnerModal } from '@/redux/modules/listing';
import {
  getIsPhoneVerified,
  hasMessagedPhoneUnverified as hasMessagedPhoneUnverifiedSelector,
} from '@/redux/selectors/auth/verifications';
import getHost from '@/redux/selectors/listing/host';
import { getListingId } from '@/redux/selectors/listing/page';
import { trackMessageHostClickedEvent } from '@/services/analytics/listings';
import { OptimizelyFlags, useOptimizelyDecision } from '@/services/experiments';
import { trackEvent } from '@/services/track-event';

const AskOwnerButtonContainer = () => {
  const dispatch = useDispatch();
  const host = useSelector(getHost);
  const hasMessagedPhoneUnverified = useSelector(hasMessagedPhoneUnverifiedSelector);
  const isPhoneVerified = useSelector(getIsPhoneVerified);
  const rentalId = useSelector(getListingId);
  const listingOrderDecision = useOptimizelyDecision(OptimizelyFlags.LISTING_ORDER);

  const isDealer = host?.dealer || false;
  const isHostBioAboveFeaturesVariation =
    listingOrderDecision?.variationKey === 'host_bio_above_features';
  // Show host response time when variation is not host bio above features, or listing is of a dealer
  const showResponseTime = !isHostBioAboveFeaturesVariation || isDealer;

  const handleOpenModal = useCallback(() => {
    if (rentalId) {
      trackEvent({
        event: 'Listing : Contact owner',
        rental_id: rentalId,
      });
      trackMessageHostClickedEvent({
        rentalID: rentalId,
        recipientUserID: host?.id,
        isDealer: host?.dealer ?? undefined,
      });
    }

    if (hasMessagedPhoneUnverified && !isPhoneVerified) {
      dispatch(closeAskHostModal());
      return dispatch(openPhoneVerificationModal());
    }

    dispatch(openAskOwnerModal());
  }, [dispatch, hasMessagedPhoneUnverified, host?.dealer, host?.id, isPhoneVerified, rentalId]);

  if (!host) {
    return null;
  }

  return <AskOwnerButton onClickButton={handleOpenModal} showResponseTime={showResponseTime} />;
};

export default AskOwnerButtonContainer;
