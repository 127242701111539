import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { BillModuleDates } from '@/components/bill/BillModuleDates/BillModuleDates';
import { useTrackListingAvailability } from '@/hooks/useTrackListingAvailability';
import { getUnavailableDates } from '@/redux/selectors/listing/availability';
import { getPricingInformation } from '@/redux/selectors/listing/bill';
import { getListingMinimumDays } from '@/redux/selectors/listing/page';

type TDatesSelection = {
  from?: Date;
  to?: Date;
};

type TBillSectionDatesContainerProps = {
  datesSelection?: TDatesSelection;
  calendarIsOpened: boolean;
  onOpenCalendar: () => void;
  onDismissCalendar: () => void;
  onConfirmDates: (from: Date, to: Date) => void;
  onClearDates: () => void;
};

export const BillSectionDatesContainerSteps: React.FC<TBillSectionDatesContainerProps> = ({
  datesSelection,
  calendarIsOpened,
  onOpenCalendar,
  onDismissCalendar,
  onConfirmDates,
  onClearDates,
}: TBillSectionDatesContainerProps) => {
  const intl = useIntl();

  const minimumDays = useSelector(getListingMinimumDays);
  const { period: priceType } = useSelector(getPricingInformation);
  const disabledDays = useSelector(getUnavailableDates);

  const trackListingAvailabilityEvent = useTrackListingAvailability();

  // This only updates the url with the new interval. There is another
  // effect that handles quote create/update for all the places in listing.
  const handleConfirmDates = async (from: Date, to: Date) => {
    trackListingAvailabilityEvent(from, to);
    onConfirmDates(from, to);
  };

  return (
    <BillModuleDates
      open={calendarIsOpened}
      onOpenCalendar={onOpenCalendar}
      onDismissCalendar={onDismissCalendar}
      from={datesSelection?.from}
      to={datesSelection?.to}
      onClearDates={onClearDates}
      onConfirmDates={handleConfirmDates}
      disabledDays={disabledDays}
      minimumDays={minimumDays}
      calendarWarning={
        minimumDays
          ? intl.formatMessage(
              {
                defaultMessage:
                  'This rental has a {minimumDays} {priceType, select, day {day} other {night}} minimum.',
                id: '7ob4IN',
                description: 'Listing Page > Bill Module > Calendar warning',
              },
              { minimumDays, priceType },
            )
          : undefined
      }
    />
  );
};
