import React from 'react';
import { useSelector } from 'react-redux';

import CancellationPolicy from '@/graphql/components/route/listing/HouseRules/CancellationPolicy';
import { getListingData } from '@/redux/selectors/listing/page';

export const CancellationPolicyContainer = () => {
  const listingData = useSelector(getListingData);

  if (!listingData) return null;

  return <CancellationPolicy rentalId={listingData.id} policy={listingData.cancel_policy} />;
};
