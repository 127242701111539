import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import ClampModal from '@/components/route/listing/ClampModal/ClampModal';
import Heading from '@/components/switchback/Heading/Heading';
import { ECancellationPolicies, POLICY_LABEL } from '@/constants/houseRules';
import { CancellationPolicyInput, useGetCancellationPolicyQuery } from '@/graphql/types-and-hooks';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useFreePeriod from '@/hooks/useFreePeriod';

import css from './CancellationPolicy.module.css';

interface ICancellationPolicyProps {
  policy: ECancellationPolicies;
  rentalId?: number;
  showModeratePolicyNote?: boolean;
  hasSiblingQuote?: boolean;
}

const CancellationPolicy: React.FC<ICancellationPolicyProps> = ({
  policy,
  rentalId,
  showModeratePolicyNote,
  hasSiblingQuote,
}) => {
  const input: CancellationPolicyInput = {};
  const { isMobile } = useBreakpoint();

  const newPolicy = hasSiblingQuote ? ECancellationPolicies.MODERATE : policy;

  if (newPolicy) {
    input.policy = newPolicy;
  }

  if (rentalId && !hasSiblingQuote) {
    input.rental_id = rentalId;
  }
  const freePeriod = useFreePeriod();
  freePeriod && (input.free_period = freePeriod);

  const { data } = useGetCancellationPolicyQuery({
    variables: {
      input,
    },
  });

  const markdown = data?.getCancellationPolicy?.policy_v2?.markdown;

  if (!markdown) return null;

  const ModalContent = () => (
    <div className={`${css.policyCentent} text-black`} data-modal="true">
      <ReactMarkdown>{markdown}</ReactMarkdown>
      {showModeratePolicyNote && (
        <p>
          <FormattedMessage
            defaultMessage="The cancellation policy will be {moderate} if the RV is delivered and set up at one of our partner campgrounds. Please check the cancellation policy at checkout before making a payment."
            id="DaHVBt"
            values={{
              moderate: <strong>moderate</strong>,
            }}
          />
        </p>
      )}
    </div>
  );

  return (
    <>
      <Heading className="h5 text-primary-base">
        <FormattedMessage
          defaultMessage="Cancellation: {policy}"
          id="hzxJ0j"
          values={{ policy: POLICY_LABEL[newPolicy] }}
        />
      </Heading>
      <ClampModal
        ellipsis
        modalTitle="Cancellation policy"
        lineCount={isMobile ? 4 : 2}
        modalContent={<ModalContent />}
        showMoreText="View details">
        <div className={`${css.policyCentent} text-secondary-base`}>
          <ReactMarkdown>{markdown}</ReactMarkdown>
        </div>
      </ClampModal>
    </>
  );
};

export default React.memo(CancellationPolicy);
