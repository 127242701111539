import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { IQuestionAnswers } from '@/services/types/faq/faqs';

interface IQuestionAnswersData {
  questionAnswers: IQuestionAnswers[];
  total: number;
}

export const getQuestionAnswers = createSelector<
  TRootState,
  IQuestionAnswers[],
  IQuestionAnswersData
>(
  state => state.listing.data?.listing_questions ?? [],
  questionAnswers => ({
    questionAnswers,
    total: questionAnswers?.length ?? 0,
  }),
);
