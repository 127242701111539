import React from 'react';
import { useSelector } from 'react-redux';

import { getListingData } from '@/redux/selectors/listing/page';

import { CampgroundData } from './CampgroundData';

export const CampgroundDataContainer = () => {
  const listingData = useSelector(getListingData);

  if (!listingData?.campground) return null;

  return (
    <CampgroundData
      name={listingData.campground.name}
      avatarName={listingData.campground.name}
      email={listingData.campground.email}
      isOutdoorsyProperty={listingData.campground.is_odn}
    />
  );
};
