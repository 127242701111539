import React from 'react';

import { TCurrency } from '@/config/locales';
import { BraintreeProvider as BaseBraintreeProvider } from '@/graphql/components/braintree/BraintreeProvider';

interface IProps {
  children: React.ReactNode;
  currency?: TCurrency;
}

const PayPalMessageProvider: React.FC<IProps> = ({ currency = 'USD', children }) => {
  return (
    <BaseBraintreeProvider currency={currency} components="messages">
      {children}
    </BaseBraintreeProvider>
  );
};

export default PayPalMessageProvider;
