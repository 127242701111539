import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BillModuleDelivery } from '@/components/bill/BillModuleDelivery/BillModuleDelivery';
import { useBillModuleProtectionData } from '@/components/bill/BillModuleProtection';
import { useQueryDeliveryUpdate } from '@/components/bill/hooks/useQueryDeliveryUpdate';
import { clearDeliveryCost } from '@/redux/modules/delivery';
import { clearQuoteDelivery, getQuote, setQuoteDelivery } from '@/redux/modules/quote';
import { getBillSummary } from '@/redux/selectors/bill/summary';
import { getDeliveryRadius } from '@/redux/selectors/listing/delivery';
import { getListingId, getListingLocation } from '@/redux/selectors/listing/page';
import { getQuoteDelivery, getQuoteServices } from '@/redux/selectors/quote';
import inlineAddressFormat from '@/services/inlineAddressFormat';
import { trackEvent } from '@/services/track-event';
import {
  EDeliveryFields,
  EDeliveryOption,
  TDeliveryFields,
} from '@/services/types/core/delivery.types';
import { EServiceTypes } from '@/services/types/core/protections';

type TBillSectionDelProps = {
  updateStep?: () => void;
  isCompleted?: boolean;
};

export const BillSectionDeliveryContainer: React.FC<TBillSectionDelProps> = ({
  updateStep,
  isCompleted,
}) => {
  const dispatch = useDispatch();
  const quoteDelivery = useSelector(getQuoteDelivery);
  const deliveryRadius = useSelector(getDeliveryRadius);
  const listingLocation = useSelector(getListingLocation);
  const rentalId = useSelector(getListingId);
  const summary = useSelector(getBillSummary);
  const quoteServices = useSelector(getQuoteServices);
  const hasDelivery = !!deliveryRadius && deliveryRadius.radius > 0;
  const deliveryValue = summary?.deliveryValue;
  const deliveryStationary =
    quoteDelivery &&
    (quoteDelivery.stationary ? EDeliveryOption.STATIONARY : EDeliveryOption.MOVING);
  useQueryDeliveryUpdate();

  const deliveryAddress = useMemo(
    () => (quoteDelivery?.location ? inlineAddressFormat(quoteDelivery.location) : ''),
    [quoteDelivery?.location],
  );

  const { availableServiceIds } = useBillModuleProtectionData();

  const handleSubmitPickup = () => {
    updateStep?.();
    dispatch(clearQuoteDelivery());
    dispatch(clearDeliveryCost());
    // ServiceIds are moved from previous quote to new quote. Make sure that when changing
    // to pick up the RSA service is removed. Removing is handled in getQuote.
    const rsaService = quoteServices?.find(
      service => service.category === EServiceTypes.ROADSIDE_ASSISTANCE,
    );
    const rsaId = rsaService?.service_id;
    dispatch(getQuote({ serviceId: rsaId, serviceShouldBeAdded: false }));
  };

  const handleSubmitDelivery = (data: TDeliveryFields, isStationary: boolean) => {
    updateStep?.();
    const deliveryCampgroundId = data[EDeliveryFields.QUOTE_DELIVERY_CAMPGROUND_ID];
    dispatch(
      setQuoteDelivery({
        address: data[EDeliveryFields.DELIVERY_ADDRESS],
        deliveryCampgroundId,
        location: data[EDeliveryFields.DELIVERY_LOCATION]
          ? JSON.parse(data[EDeliveryFields.DELIVERY_LOCATION])
          : quoteDelivery?.location,
        stationary: isStationary,
      }),
    );
    if (deliveryCampgroundId && availableServiceIds.length) {
      dispatch(getQuote({ removalServiceIds: availableServiceIds }));
    } else {
      dispatch(getQuote());
    }
  };

  const deliveryType =
    quoteDelivery?.location && deliveryStationary ? deliveryStationary : EDeliveryOption.PICKUP;

  useEffect(() => {
    if (rentalId && deliveryValue) {
      trackEvent({
        event: 'Listing : Bill delivery',
        rental_id: rentalId,
        delivery_type: deliveryType,
        delivery_cost: deliveryValue,
        location: deliveryAddress,
      });
    }
  }, [rentalId, deliveryValue, deliveryType, deliveryAddress]);

  return (
    <BillModuleDelivery
      deliveryAddress={deliveryAddress}
      deliveryLocation={quoteDelivery?.location}
      deliveryType={deliveryType}
      hasDelivery={hasDelivery}
      onPickupSubmit={handleSubmitPickup}
      onDeliverySubmit={handleSubmitDelivery}
      listingLocation={listingLocation}
      isCompleted={isCompleted}
    />
  );
};
