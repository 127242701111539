import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FAQ } from '@/components/route/listing/QuestionAnswers/FAQ';
import Divider from '@/components/switchback/Divider/Divider';
import Heading from '@/components/switchback/Heading/Heading';
import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link from '@/components/switchback/Link/Link';
import { listingPageAnchors } from '@/constants/anchors';
import { FAQ_HELP_PAGE, HOW_IT_WORKS_PAGE } from '@/constants/urls';

interface IProps {
  questionAnswers: IQuestionAnswers[] | undefined;
  total: number;
  showLimit: number;
  customTitle?: string;
  hideLinks?: boolean;
}
interface IQuestionAnswers {
  question: string;
  answer: string;
}

const QuestionAnswers: React.FC<IProps> = ({
  total,
  questionAnswers,
  showLimit = 4,
  customTitle,
  hideLinks = false,
}) => {
  const [showAll, setShowAll] = React.useState(false);
  const toggleRemaining = () => setShowAll(!showAll);

  if (!questionAnswers?.length) return null;

  return (
    <div
      className="gap-x-default reset-col-offset lg:grid lg:grid-cols-16"
      id={listingPageAnchors.FAQ}
      data-testid="questions-answers-module">
      <div className="md:col-span-10 lg:col-span-4">
        <div
          className="flex items-center justify-between lg:flex-col lg:items-start"
          data-testid="listing-reviews-module">
          <div className="flex flex-col items-baseline w-full lg:flex-col md:flex-row md:justify-between">
            {!customTitle ? (
              <Heading level={2} className="text-3xl highlight">
                <span className="block leading-8">
                  <FormattedMessage defaultMessage="Have a question?" id="LrwC3/" />
                </span>
                <FormattedMessage defaultMessage="Get answers directly from the host" id="aCEP3N" />
              </Heading>
            ) : (
              <Heading level={2} className="text-3xl highlight">
                {customTitle}
              </Heading>
            )}
            {!hideLinks && (
              <div
                className="flex items-center justify-between mt-8 font-bold autoType400"
                data-testid="questions-answers-links">
                <Link href={FAQ_HELP_PAGE} target="_blank">
                  <FormattedMessage defaultMessage="Visit our FAQ" id="mtJNq0" />
                  <Icon width={10} className={'transform rotate-180'} name={CARET_LARGE} />
                </Link>
                <Link className="ml-6" target="_blank" href={HOW_IT_WORKS_PAGE}>
                  <FormattedMessage defaultMessage="How it works" id="HK3ph8" />
                  <Icon width={10} className={'transform rotate-180'} name={CARET_LARGE} />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-6 col-start-1 md:col-span-10 lg:col-start-7 lg:col-span-10 mt-14 md:mt-10 lg:mt-0">
        {questionAnswers.slice(0, showLimit).map((faq, i) => (
          <FAQ key={`faq${i}`} idx={i} faq={faq} />
        ))}
        {total > showLimit ? (
          <>
            {showAll && (
              <>
                <Divider className="my-6" />
                {questionAnswers.slice(showLimit, total).map((faq, i) => (
                  <FAQ key={`faq${i}`} idx={i} faq={faq} />
                ))}
              </>
            )}
            <Divider className="my-6" />
            <Link onClick={toggleRemaining} target="_blank">
              {showAll ? (
                <FormattedMessage
                  defaultMessage="Hide additional questions and answers"
                  id="bPGGpf"
                />
              ) : (
                <FormattedMessage defaultMessage="Show all question and answers" id="wUibvn" />
              )}
              <Icon
                width={10}
                className={`transform ${showAll ? 'rotate-90' : '-rotate-90'}`}
                name={CARET_LARGE}
              />
            </Link>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default QuestionAnswers;
