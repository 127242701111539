import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from '@/hooks/useRouter';
import { useVisibilityState } from '@/hooks/useVisibilityState';
import { clearQuote, clearQuoteDelivery } from '@/redux/modules/quote';
import { getIsStay } from '@/redux/selectors/listing/listingHeader';
import { getFromAndTo } from '@/redux/selectors/queryParams';
import { getQuoteData, getQuoteError } from '@/redux/selectors/quote';
import formatDate from '@/utility/format-date';
import { getParamAsString, getParams } from '@/utility/queryParams';
import { createListingUrlObj, updateUrl } from '@/utility/routes';

export enum EQuoteStep {
  DATE,
  /** pickup | delivery */
  FULFILLMENT,
  INSURANCE,
  OCCUPANCY,
  PROTECTION,
  ADDON,
  REQUEST,
}

export const useBillSelectionStep = () => {
  const router = useRouter();
  const params = getParams(router);
  const dispatch = useDispatch();

  const queryFromAndTo = useSelector(getFromAndTo);
  const quoteData = useSelector(getQuoteData);
  const isStay = useSelector(getIsStay);
  const quoteError = useSelector(getQuoteError);

  const queryDatesSelection = useMemo(() => {
    if (queryFromAndTo.from && queryFromAndTo.to) {
      return {
        from: dayjs(queryFromAndTo.from).toDate(),
        to: dayjs(queryFromAndTo.to).toDate(),
      };
    }
  }, [queryFromAndTo.from, queryFromAndTo.to]);

  const quoteDatesSelection = useMemo(() => {
    if (quoteData?.from && quoteData?.to) {
      return {
        from: dayjs(quoteData.from).toDate(),
        to: dayjs(quoteData.to).toDate(),
      };
    }
  }, [quoteData?.from, quoteData?.to]);

  const datesSelection = queryDatesSelection || quoteDatesSelection;

  const queryStep = params.step ? Number(getParamAsString(params.step)) : undefined;
  const validQueryStep = queryStep !== undefined && EQuoteStep[queryStep] ? queryStep : undefined;
  // If we have fetched the quote and have a valid step, consider that is the current one.
  // If the quote is not fetched yet, and the error is because of the delivery range, go to the fultillment step
  // When we have a quote and step is invalid or missing from url, consider we finish all steps.
  const currentStep = quoteData
    ? (validQueryStep ?? EQuoteStep.REQUEST)
    : quoteError?.toLowerCase().includes('range provided exceeds') // temp, until backend supports driveable distance instead of geodistance
      ? EQuoteStep.FULFILLMENT
      : EQuoteStep.DATE;

  const moveToStep = useCallback(
    (nextStep: EQuoteStep) => {
      let possiblyNextStep = nextStep;

      // Skip fulfillment and insurance for Stays
      if (isStay) {
        while ([EQuoteStep.FULFILLMENT, EQuoteStep.INSURANCE].includes(possiblyNextStep)) {
          possiblyNextStep++;
        }
      }

      // Currently, if you don't want to select any addon's, you
      // can only close the dialog, which is not that clear.
      // Until we get a better UX we would always skip this step.
      if (possiblyNextStep === EQuoteStep.ADDON) {
        possiblyNextStep++;
      }

      // skip occupancy step, it is auto-filled during quote creation
      // the step can be used to modify the occupancy for campsites, but it is not required
      if (possiblyNextStep === EQuoteStep.OCCUPANCY) {
        possiblyNextStep++;
      }

      const shouldMoveToLastStep = possiblyNextStep === EQuoteStep.REQUEST;

      updateUrl('replace', createListingUrlObj, {
        paramsToOmit: shouldMoveToLastStep ? ['step'] : undefined,
        paramsToOverride: shouldMoveToLastStep ? undefined : { step: possiblyNextStep },
      });
    },
    [isStay],
  );

  const moveToNextStepFrom = useCallback(
    (fromStep: EQuoteStep) => {
      // The steps should go only one direction
      if (fromStep === currentStep) {
        moveToStep(fromStep + 1);
      }
    },
    [currentStep, moveToStep],
  );

  const {
    isVisible: isCalendarOpened,
    show: handleOpenCalendar,
    hide: handleDismissCalendar,
  } = useVisibilityState();

  const handleConfirmDates = (from: Date, to: Date) => {
    handleDismissCalendar();

    updateUrl('push', createListingUrlObj, {
      paramsToOmit: ['quote'],
      paramsToOverride: {
        from: from && formatDate(from, 'YYYY-MM-DD'),
        to: to && formatDate(to, 'YYYY-MM-DD'),
      },
    });
  };

  const handleClearDates = () => {
    handleDismissCalendar();

    // Clear quote from url & redux
    dispatch(clearQuote());
    dispatch(clearQuoteDelivery());

    updateUrl('push', createListingUrlObj, {
      paramsToOmit: ['quote', 'from', 'to', 'step'],
    });
  };

  // Make sure to move to the next step once we got query selection changed.
  useEffect(() => {
    if (queryDatesSelection?.from && queryDatesSelection?.to) moveToNextStepFrom(EQuoteStep.DATE);
  }, [queryDatesSelection?.from, queryDatesSelection?.to]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    currentStep,
    moveToStep,
    moveToNextStepFrom,
    isCalendarOpened,
    handleOpenCalendar,
    handleDismissCalendar,
    datesSelection,
    handleConfirmDates,
    handleClearDates,
  };
};
