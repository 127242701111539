import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getCurrency } from '@/redux/selectors/currency';
import { getListingSettlementCurrency } from '@/redux/selectors/listing/page';

const BillSectionCurrencyNotificationContainer: React.FC = () => {
  const intl = useIntl();
  const listingCurrency = useSelector(getListingSettlementCurrency);
  const quoteCurrency = useSelector(getCurrency);

  const warning = intl.formatMessage({
    defaultMessage:
      "You will be charged in this listing's local currency. A foreign exchange rate and fees may be applied by your card issuer at the time of billing.",
    id: 'BbnPTz',
    description: 'Listing Page > Bill Module > Currency exchange warning',
  });

  if (listingCurrency === quoteCurrency) {
    return null;
  }

  return <div className="my-4 text-gray-500 lg:mb-0 autoType200">{warning}</div>;
};

export default BillSectionCurrencyNotificationContainer;
