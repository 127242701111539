import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useBreakpoint } from '@/hooks/useBreakpoint';
import { closeMobileBillModule } from '@/redux/modules/listing';
import { TRootState } from '@/redux/rootReducer';
import { getIsListingPublished } from '@/redux/selectors/listing/page';
import { getIsListingAllowedByWhitelist } from '@/redux/selectors/stayStates';
import { enableBodyScroll } from '@/utility/bodyScroll';

import BillSectionMobileBarContainer from '../BillSection/BillSectionMobileBarContainer';
import css from './BillSection.module.css';
import BillSectionModal from './BillSectionModal/BillSectionModal';
import BillSectionUnpublished from './BillSectionUnpublished/BillSectionUnpublished';
import { BillSelectionStep } from './BillSelectionStep/BillSelectionStep';

export const BillSelection = () => {
  const dispatch = useDispatch();
  const { isAboveDesktop } = useBreakpoint();
  const isListingAllowedByWhitelist = useSelector(getIsListingAllowedByWhitelist);
  const isPublished = useSelector(getIsListingPublished);
  const isMobileBillModuleOpen = useSelector<TRootState, boolean>(
    state => state.listing.isMobileBillModuleOpen,
  );
  const handleCloseMobileBill = useCallback(
    (event?: React.MouseEvent) => {
      event?.preventDefault();

      enableBodyScroll();

      dispatch(closeMobileBillModule());
    },
    [dispatch],
  );

  useEffect(() => {
    if (isMobileBillModuleOpen && isAboveDesktop) {
      handleCloseMobileBill();
    }
  }, [isMobileBillModuleOpen, isAboveDesktop, handleCloseMobileBill]);

  // enable body scroll on unmount
  useEffect(() => () => enableBodyScroll(), []);

  return (
    <>
      <div className={css.billModule} data-open-mobile={isMobileBillModuleOpen}>
        <BillSectionModal onCloseMobile={handleCloseMobileBill}>
          {isPublished && isListingAllowedByWhitelist ? (
            <BillSelectionStep />
          ) : (
            <BillSectionUnpublished />
          )}
        </BillSectionModal>
      </div>

      <div
        // Only visible on viewport > lg
        className={css.mobileBarContainer}
        // This is being used by Help menu in GTM to position the button properly
        data-sticky="true">
        <BillSectionMobileBarContainer />
      </div>
    </>
  );
};
