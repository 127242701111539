import React, { useState } from 'react';

import styles from './CodeInput.module.css';

interface CodeInputProps {
  numInputs?: number;
  onFilled?: (value: string) => void;
  onPartial?: (value: string) => void;
  onChange?: (value: string) => void;
}

const CodeInput: React.FC<CodeInputProps> = ({ numInputs = 4, ...props }) => {
  const [values, setValues] = useState<string[]>([]);

  const handleKeyDown = (event: any) => {
    event.preventDefault();

    const { key, target } = event;
    const value = isNaN(key) ? null : key;
    const inputIndex = Number(target.dataset.id);
    const nextElement = target.form.elements[inputIndex + 1];
    const prevElement = target.form.elements[inputIndex - 1];
    const updatedValues = [...values];

    updatedValues[inputIndex] = value;

    if (value !== null) {
      setValues(updatedValues);

      if (nextElement) {
        nextElement.focus();
      }
    } else if (key === 'Backspace') {
      setValues(updatedValues);

      if (prevElement) {
        prevElement.focus();
      }
    } else if (prevElement && key === 'ArrowLeft') {
      prevElement.focus();
    } else if (nextElement && key === 'ArrowRight') {
      nextElement.focus();
    }

    const result = updatedValues.join('');

    // Handlers
    if (result.length === numInputs) {
      if (props.onFilled) {
        props.onFilled(result);
      }
    } else if (result.length > 0) {
      if (props.onPartial) {
        props.onPartial(result);
      }
    }

    if (props.onChange) {
      props.onChange(result);
    }
  };

  const inputComponents = [];

  for (let i = 0; i < numInputs; ++i) {
    inputComponents.push(
      <input
        key={i}
        data-id={i}
        className={`${styles.TextInput} w-8 text-2xl font-bold leading-tight text-center text-gray-700 bg-white border-b-2 py-2 appearance-none`}
        type="number"
        pattern="[0-9]*"
        min={0}
        max={9}
        maxLength={1}
        value={values[i] || ''}
        onKeyDown={handleKeyDown}
        onChange={() => {
          return null;
        }}
      />,
    );
  }

  return <form className={styles.CodeInput}>{inputComponents}</form>;
};

export default CodeInput;
