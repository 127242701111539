import React from 'react';
import { useSelector } from 'react-redux';

import { IconType } from '@/components/switchback/Icon/IconComponent';
import {
  EHouseRules,
  EStayHouseRules,
  HOUSE_RULES,
  ITranslation,
  STAY_HOUSE_RULES,
} from '@/constants/houseRules';
import { TRootState } from '@/redux/rootReducer';
import {
  getListingFeatures,
  getListingGeneratorRules,
  getListingHouseRules,
  getListingMileageRules,
  getStayHouseRules,
  getStayListingFeatures,
  getStayParkingRules,
} from '@/redux/selectors/listing/houseRules';
import { getIsStay } from '@/redux/selectors/listing/listingHeader';
import { getListingId } from '@/redux/selectors/listing/page';

import HouseRules from './HouseRules';

export interface IOwnerRule {
  icon: IconType;
  label: ITranslation;
  allowed: boolean;
  tooltip?: string;
}

const HouseRulesContainer: React.FC<{ className?: string }> = ({ className = '' }) => {
  const isStay = useSelector(getIsStay);
  const houseRules = useSelector(getListingHouseRules);
  const features = useSelector(isStay ? getStayListingFeatures : getListingFeatures);
  const mileageRules = useSelector((state: TRootState) =>
    getListingMileageRules(state, true, true),
  );
  const generatorRules = useSelector((state: TRootState) => getListingGeneratorRules(state, true));
  const stayCheckinRules = useSelector(getStayHouseRules);
  const parkingRules = useSelector(getStayParkingRules);
  const rentalId = useSelector(getListingId);

  const ownerRules: IOwnerRule[] = isStay
    ? STAY_HOUSE_RULES.map(
        ({ feature, allowedIcon, notAllowedIcon, allowedTitle, notAllowedTitle }) => ({
          icon: features[feature as EStayHouseRules] ? allowedIcon : notAllowedIcon,
          label: features[feature as EStayHouseRules] ? allowedTitle : notAllowedTitle,
          allowed: !!features[feature as EStayHouseRules],
        }),
      )
    : HOUSE_RULES.map(
        ({ feature, allowedIcon, notAllowedIcon, allowedTitle, notAllowedTitle }) => ({
          icon: features[feature as EHouseRules] ? allowedIcon : notAllowedIcon,
          label: features[feature as EHouseRules] ? allowedTitle : notAllowedTitle,
          allowed: !!features[feature as EHouseRules],
        }),
      );

  if (!houseRules) {
    return null;
  }

  return (
    <HouseRules
      className={className}
      ownerRules={ownerRules}
      mileageRules={mileageRules || undefined}
      generatorRules={generatorRules || undefined}
      stayCheckinRules={stayCheckinRules || undefined}
      parkingRules={parkingRules || undefined}
      houseRulesText={houseRules.house_rules}
      cancelationPolicy={houseRules.cancel_policy}
      isStay={isStay}
      rentalId={rentalId}
    />
  );
};

export default HouseRulesContainer;
