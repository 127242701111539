import React from 'react';
import { useSelector } from 'react-redux';

import Divider from '@/components/switchback/Divider/Divider';
import { TRootState } from '@/redux/rootReducer';
import { getListingHeader } from '@/redux/selectors/listing/listingHeader';
import { getListingId } from '@/redux/selectors/listing/page';

import ListingHeader, { IProps as IListingHeaderProps } from './ListingHeader';

interface IProps {
  onLinkClick: IListingHeaderProps['onLinkClick'];
  divider?: boolean;
  shouldShowProBadge?: boolean;
}

const ListingHeaderContainer: React.FC<IProps> = ({
  onLinkClick,
  divider = true,
  shouldShowProBadge,
}) => {
  const data = useSelector(getListingHeader);
  const isLoading = useSelector<TRootState, boolean>(state => Boolean(state.listing.isLoading));
  const rentalId = useSelector(getListingId);

  return (
    <>
      <ListingHeader
        loading={isLoading}
        onLinkClick={onLinkClick}
        showProBadge={shouldShowProBadge && data?.dealer}
        rentalId={rentalId}
        {...data}
      />
      {divider && <Divider className="mt-2 md:mt-6" />}
    </>
  );
};

export default ListingHeaderContainer;
