import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Button, { ButtonSize, ButtonVariants } from '@/components/switchback/Button/Button';
import Heading from '@/components/switchback/Heading/Heading';
import { ALERT_OCTAGON, CALENDAR } from '@/components/switchback/Icon/assets';
import IconComponent from '@/components/switchback/Icon/IconComponent';
import Notice, { NoticeType } from '@/components/switchback/Notice/Notice';
import Text from '@/components/switchback/Text/Text';
import { getCurrency } from '@/redux/selectors/currency';
import { getRequiredAddOns } from '@/redux/selectors/listing/addons';
import { getIsUnavailable } from '@/redux/selectors/listing/availability';
import { getListingHouseRules } from '@/redux/selectors/listing/houseRules';
import { getListingMinimumDays, getListingPriceType } from '@/redux/selectors/listing/page';

import AvailabilityCalendar from './AvailabilityCalendar/AvailabilityCalendar';
import Discounts from './Discounts';
import Fees from './Fees';

const Availability: React.FC = () => {
  const isUnavailable = useSelector(getIsUnavailable);
  const minimumDays = useSelector(getListingMinimumDays) || 1;
  const priceType = useSelector(getListingPriceType);
  const houseRules = useSelector(getListingHouseRules);
  const addons = useSelector(getRequiredAddOns);
  const currency = useSelector(getCurrency);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Heading level={2} className="mb-6 font-bold md:mb-2 md:h3 text-700 semiHighlight">
        <FormattedMessage defaultMessage="Availability and rates" id="NT93+R" />
      </Heading>
      {minimumDays > 1 && (
        <div className="items-center hidden md:flex">
          <IconComponent className="text-gray-500" name={CALENDAR} />
          <Text
            className="ml-3 text-base text-primary-base semiHighlight"
            type="paragraph"
            data-testid="minimum-nights-warning">
            <FormattedMessage
              defaultMessage="This rental has a {minimumDays} {priceType, select, day {day} other {night}} minimum."
              id="TjE64s"
              values={{ minimumDays, priceType }}
            />
          </Text>
        </div>
      )}

      {isUnavailable && (
        <div className="text-red-800 text autoType300" data-testid="unavailable-dates-error">
          <Notice variant={NoticeType.critical} icon={ALERT_OCTAGON}>
            <FormattedMessage defaultMessage="Your dates are not available" id="pJtEek" />
          </Notice>
        </div>
      )}

      <div className="block md:hidden">
        <Button
          className="w-full"
          size={ButtonSize.large}
          variant={ButtonVariants.primaryOutlined}
          onClick={() => setOpen(true)}>
          <FormattedMessage defaultMessage="Check available dates" id="IAOBba" />
        </Button>
      </div>
      <AvailabilityCalendar show={open} onClose={() => setOpen(false)} />

      <Text
        type="paragraph"
        className="mt-4 text-base text-center semiHighlight md:hidden"
        data-testid="minimum-days-charging-message">
        <FormattedMessage
          defaultMessage="There’s a {minimumDays} {priceType, select, day {day} other {night}} minimum stay"
          id="Wqnodk"
          values={{ minimumDays: minimumDays, priceType }}
        />
      </Text>

      {houseRules && (
        <div>
          <Discounts
            weeklyDiscountPercentage={houseRules.weekly_discount_percentage}
            monthlyDiscountPercentage={houseRules.monthly_discount_percentage}
            priceType={priceType}
          />
          <Fees
            securityDeposit={houseRules.security_deposit}
            prepFee={houseRules.prep_fee?.amount}
            prepFeeDescription={houseRules.prep_fee?.description}
            addons={addons}
            currency={currency}
          />
        </div>
      )}
    </>
  );
};

export default Availability;
