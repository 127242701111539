import { Card, ECardCarouselSize } from '@outdoorsyco/bonfire';
import isEqual from 'lodash/isEqual';
import Link from 'next/link';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import PhoneVerificationModalContainer from '@/components/auth/PhoneVerificationModalContainer';
import SimilarRentalsCarousel from '@/components/common/SimilarRentalsCarousel';
import ErrorPage from '@/components/route/error/ErrorPage/ErrorPage';
import AddOnsSectionContainer from '@/components/route/listing/AddOns/AddOnsSectionContainer';
import AmenitiesContainer from '@/components/route/listing/Amenities/AmenitiesContainer';
import CampsiteCampgroundAmenitiesContainer from '@/components/route/listing/Amenities/CampsiteCampgroundAmenitiesContainer';
import AskOwnerButtonContainer from '@/components/route/listing/AskHostButton/AskHostButtonContainer';
import AskOwnerModalContainer from '@/components/route/listing/AskHostModal/AskHostModalContainer';
import Availability from '@/components/route/listing/Availability/Availability';
import { BillSelection } from '@/components/route/listing/BillSectionSelection/BillSectionSelection';
import Breadcrumbs from '@/components/route/listing/Breadcrumbs/Breadcrumbs';
import { CampgroundDataContainer } from '@/components/route/listing/CampgroundData';
import { CampgroundHighlightsContainer } from '@/components/route/listing/CampgroundHighlights';
import { CampgroundRulesContainer } from '@/components/route/listing/CampgroundRules';
import { CancellationPolicyContainer } from '@/components/route/listing/CancellationPolicy';
import Head from '@/components/route/listing/Head/Head';
import HeaderActionsContainer from '@/components/route/listing/HeaderActions/HeaderActionsContainer';
import HostBioContainer from '@/components/route/listing/HostBio/HostBioContainer';
import HouseRulesContainer from '@/components/route/listing/HouseRules/HouseRulesContainer';
import ListingHeaderContainer from '@/components/route/listing/ListingHeader/ListingHeaderContainer';
import LocationContainer from '@/components/route/listing/Location/LocationContainer';
import QuestionAnswersContainer from '@/components/route/listing/QuestionAnswers/QuestionAnswersContainer';
import RentalDetailsContainer from '@/components/route/listing/RentalDetails/RentalDetailsContainer';
import ReviewsContainer from '@/components/route/listing/Reviews/ReviewsContainer';
import SectionWithDivider from '@/components/route/listing/SectionWithDivider/SectionWithDivider';
import SeoContentContainer from '@/components/route/listing/SeoContent/SeoContentContainer';
import { STICKY_NAV_OFFSET } from '@/components/route/listing/StickyNav/StickyNav';
import StickyNavContainer from '@/components/route/listing/StickyNav/StickyNavContainer';
import PhotoGallery from '@/components/ui/PhotoGallery/PhotoGallery';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { listingPageAnchors } from '@/constants/anchors';
import { SIMILAR_RENTALS_LIMIT } from '@/constants/listing';
import { PHOTOS } from '@/constants/urls';
import { EVehicleDetails, VEHICLE_DETAILS_ICONS } from '@/constants/vehicleDetails';
import { DeliveryModalProvider } from '@/context/DeliveryModalContext';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useIsFirstRender } from '@/hooks/useIsFirstRender';
import { useRouter } from '@/hooks/useRouter';
import { getAvailability } from '@/redux/modules/availability';
import { getInsuranceBundles } from '@/redux/modules/insuranceBundles';
import { getListing } from '@/redux/modules/listing';
import { ownerInfoSelector } from '@/redux/modules/owner/selectors';
import { fetchQuoteById, getQuote } from '@/redux/modules/quote';
import { getRentalItems } from '@/redux/modules/rentalItems';
import { getReviews } from '@/redux/modules/reviews';
import { getSimilarRentals } from '@/redux/modules/similarRentals';
import { getListingViewedEventData } from '@/redux/selectors/analytics-selectors';
import { getIsAuthenticated } from '@/redux/selectors/auth/user';
import {
  getIsPhoneVerified,
  hasMessagedPhoneUnverified,
} from '@/redux/selectors/auth/verifications';
import { getUserCurrency } from '@/redux/selectors/currency';
import { getListingBreadcrumbs } from '@/redux/selectors/listing/breadcrumbs';
import { getDeliveryRadius } from '@/redux/selectors/listing/delivery';
import { getGallery } from '@/redux/selectors/listing/gallery';
import {
  selectNearbyCampgrounds,
  selectNearbyCampgroundsLoaded,
} from '@/redux/selectors/listing/nearbyCampgrounds';
import {
  getIsListingLoading,
  getListingData,
  getListingEventData,
  getListingId,
  getListingPresentmentCurrency,
} from '@/redux/selectors/listing/page';
import { getRentalDetails } from '@/redux/selectors/listing/rentalDetails';
import { getDeliveryFromQuery, getFromAndTo, getQueryParams } from '@/redux/selectors/queryParams';
import {
  getQuoteBooking,
  getQuoteData,
  getQuoteDeliverableCampgrounds,
  getQuoteDelivery,
  getQuoteId,
} from '@/redux/selectors/quote';
import {
  trackListingPageGalleryViewedEvent,
  trackListingViewedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import {
  OptimizelyFlags,
  useExperimentIsEnabled,
  useOptimizelyDecision,
} from '@/services/experiments';
import { getModuleOrderFromOptimizely } from '@/services/experiments/listingOrder';
import { trackEvent } from '@/services/track-event';
import { isProduction } from '@/utility/isSSR';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@/utility/localstorage';
import { getParamAsString } from '@/utility/queryParams';

import Features from '../Features/Features';
import { OutdoorsyStaysBanner } from './OutdoorsyStaysBanner';

interface IError {
  statusCode: number;
  message?: string;
}

interface IProps {
  error?: IError | null;
  isCampsite?: boolean;
  isStay?: boolean;
}

const modulesWithoutDividers = ['host-bio'];

const ListingIndex: React.FC<IProps> = ({ error, isCampsite, isStay }) => {
  const intl = useIntl();
  const router = useRouter();
  const { isMobile } = useBreakpoint();
  const imagesHref = `${router.route}${PHOTOS}`;

  const { quote: quoteQueryId } = useSelector(getQueryParams);
  const quoteBooking = useSelector(getQuoteBooking);
  const dispatch = useDispatch();
  const isFirstRender = useIsFirstRender();
  const didTrackProductView = useRef(false);
  const event = useSelector(getListingEventData);
  const breadcrumbs = useSelector(getListingBreadcrumbs);
  const listingCurrency = useSelector(getListingPresentmentCurrency);
  const rentalId = useSelector(getListingId);
  const isPhoneVerified = useSelector(getIsPhoneVerified);
  const isMessagedPhoneUnverified = useSelector(hasMessagedPhoneUnverified);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const quoteId = useSelector(getQuoteId);
  const images = useSelector(getGallery);
  const userCurrency = useSelector(getUserCurrency);
  const upperSectionRef = useRef<HTMLElement>(null);
  const lowerSectionRef = useRef<HTMLDivElement>(null);
  const isLoading = useSelector(getIsListingLoading);
  const stationaryDelivery = useSelector(getQuoteDelivery)?.stationary;
  const listingData = useSelector(getListingData);
  const queryFromAndTo = useSelector(getFromAndTo, isEqual);
  const quoteData = useSelector(getQuoteData);
  const deliveryDataFromQuery = useSelector(getDeliveryFromQuery, isEqual);
  const deliveryRadius = useSelector(getDeliveryRadius);
  const rentalHasDelivery = !!deliveryRadius && deliveryRadius.radius > 0;

  const quoteDeliverableCampgrounds = useSelector(getQuoteDeliverableCampgrounds);
  const shouldShowCampgroundBanner = !!quoteDeliverableCampgrounds?.length;

  // Only fetched for RVs for now. We won't wait on this if it's a Stay/Campsite
  const nearbyCampgrounds = useSelector(selectNearbyCampgrounds);
  const nearbyCampgroundsLoaded = useSelector(selectNearbyCampgroundsLoaded);
  const isNearbyCampgroundsLoadedOrDoesNotNeedToLoad =
    nearbyCampgroundsLoaded || isStay || isCampsite;

  const rentalDetails = useSelector(getRentalDetails);
  const ownerInfo = useSelector(ownerInfoSelector);

  const isDealer = ownerInfo?.dealer || false;
  const listingViewedData = useSelector(getListingViewedEventData);
  const [isEventTriggered, setIsEventTriggered] = useState(false);

  const listingOrderDecision = useOptimizelyDecision(
    isCampsite ? OptimizelyFlags.CAMPSITE_LISTING_ORDER : OptimizelyFlags.LISTING_ORDER,
  );
  const shouldShowProBadge =
    useExperimentIsEnabled(OptimizelyFlags.SHOW_PRO_BADGE) && !isCampsite && !isStay;

  const rentalDetailsData = rentalDetails;
  const detailsList = useMemo(() => {
    return Object.entries(rentalDetailsData?.details || {}).map(([key, item]) => ({
      id: key as EVehicleDetails,
      icon: VEHICLE_DETAILS_ICONS[key as EVehicleDetails],
      ...item,
    }));
  }, [rentalDetailsData?.details]);

  const extraQueryParams = useMemo(() => {
    if (quoteData && quoteData.from && quoteData.to) {
      return `from=${quoteData.from}&to=${quoteData.to}`;
    } else if (queryFromAndTo.from && queryFromAndTo.to) {
      return `from=${queryFromAndTo.from}&to=${queryFromAndTo.to}`;
    } else {
      return '';
    }
  }, [queryFromAndTo, quoteData]);

  const defaultOrder = isCampsite
    ? {
        topOrder: [
          'details',
          'amenities',
          'campground-features',
          'location-highlights',
          'rules-and-policies',
          'cancellation-policy',
          'campground-details',
        ],
        bottomOrder: ['location', 'reviews', 'similar-rentals-carousel'],
      }
    : undefined;

  const { topOrder, bottomOrder } = getModuleOrderFromOptimizely(
    // will force RV dealers into default order
    !isCampsite && isDealer ? null : listingOrderDecision,
    defaultOrder,
  );

  const TOP_MODULE_MAP: Record<string, React.ReactNode> = isCampsite
    ? {
        details: <RentalDetailsContainer />,
        amenities: <AmenitiesContainer />,
        'campground-features': <CampsiteCampgroundAmenitiesContainer />,
        'location-highlights': <CampgroundHighlightsContainer />,
        'rules-and-policies': <CampgroundRulesContainer />,
        'cancellation-policy': <CancellationPolicyContainer />,
        'campground-details': <CampgroundDataContainer />,
      }
    : {
        'message-owner': (
          <div className="mb-6 md:mb-8">
            <AskOwnerButtonContainer />
          </div>
        ),
        'vehicle-details': <RentalDetailsContainer />,
        amenities: <AmenitiesContainer />,
        'house-rules': <HouseRulesContainer />,
        'addons-section': <AddOnsSectionContainer />,
        availability: <Availability />,
        'host-bio': (
          <div className="mb-6 md:mb-8">
            <HostBioContainer />
          </div>
        ),
        features:
          rentalDetailsData && detailsList && detailsList?.length > 0 ? (
            <Features details={detailsList} vehicleType={rentalDetailsData.vehicleType} />
          ) : null,
      };

  const BOTTOM_MODULE_MAP: Record<string, React.ReactNode> = isCampsite
    ? {
        location: <LocationContainer isCampsite />,
        reviews: <ReviewsContainer isCampsite />,
        'similar-rentals-carousel': (
          <SimilarRentalsCarousel
            title={intl.formatMessage({
              defaultMessage: 'Similar rentals available on your dates',
              id: 'sqFRj9',
            })}
            inline
            useRentalsFromId
            eventSource={EListingSource.LISTING_PAGE}
            extraQueryParams={extraQueryParams}
          />
        ),
      }
    : {
        reviews: <ReviewsContainer isStay={isStay} />,
        'question-answers': <QuestionAnswersContainer />,
        location: <LocationContainer isStay={isStay} />,
        'similar-rentals-carousel': !isStay && (
          <SimilarRentalsCarousel
            title={intl.formatMessage({
              defaultMessage: 'Similar rentals available on your dates',
              id: 'sqFRj9',
            })}
            inline
            useRentalsFromId
            eventSource={EListingSource.LISTING_PAGE}
            extraQueryParams={extraQueryParams}
          />
        ),
        'seo-content': !isStay && <SeoContentContainer />,
        breadcrumbs: !!breadcrumbs && !isStay && (
          <nav aria-label="Breadcrumbs" className="pt-7 md:pt-5 lg:pt-7">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </nav>
        ),
      };

  const finalTopOrder = topOrder.filter(slug => !!TOP_MODULE_MAP[slug]);
  const finalBottomOrder = bottomOrder.filter(slug => !!BOTTOM_MODULE_MAP[slug]);

  // Make sure query quote matches the one we have in redux.
  // On dates change we no longer generate new quote, so make
  // sure update the url in these cases as well.
  useEffect(() => {
    if (!quoteId || quoteId === router.query.quote) return;

    router.replace(
      {
        query: {
          ...router.query,
          quote: quoteId,
        },
      },
      undefined,
      { scroll: false },
    );
  }, [quoteId, router]);

  useEffect(() => {
    if (isNearbyCampgroundsLoadedOrDoesNotNeedToLoad && listingViewedData) {
      listingViewedData['totalNearbyCampgrounds'] = nearbyCampgrounds.length;
    }

    // if not quote data will be loaded then trigger event listener
    if (
      listingViewedData &&
      !listingViewedData?.departureDate &&
      !listingViewedData?.returnDate &&
      !window.location.href.includes('quote') &&
      !isFirstRender &&
      !isEventTriggered &&
      isNearbyCampgroundsLoadedOrDoesNotNeedToLoad
    ) {
      trackListingViewedEvent(listingViewedData);
      setIsEventTriggered(true);
    }

    // wait for quote data to send complete data
    if (
      listingViewedData?.totalCost &&
      !isEventTriggered &&
      isNearbyCampgroundsLoadedOrDoesNotNeedToLoad
    ) {
      trackListingViewedEvent(listingViewedData);
      setIsEventTriggered(true);
    }
  }, [
    event,
    isFirstRender,
    isEventTriggered,
    listingViewedData,
    isNearbyCampgroundsLoadedOrDoesNotNeedToLoad,
    nearbyCampgrounds,
  ]);

  useEffect(() => {
    // We only want to track this event once, and only if the data is loaded.
    // If the listing data isn't loaded or we already tracked the event, return.
    if (didTrackProductView.current || !event.product_id) return;
    const timeout = setTimeout(() => {
      didTrackProductView.current = true;
      trackEvent(event);
    }, 100);
    return () => clearTimeout(timeout);
  }, [event]);

  // Fetch only if there is a mismatch between query and redux quote.
  // This is also going to cover the initial fetch when quote is in the url.
  useEffect(() => {
    const returnQuoteId = getParamAsString(quoteQueryId);
    if (returnQuoteId && rentalId && returnQuoteId !== quoteId) {
      dispatch(fetchQuoteById(returnQuoteId, rentalId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, quoteQueryId, rentalId]);

  // To change quote interval, we are changing from & to in the url.
  // This is going to make sure we fetch/re-fetch on dates change.
  // This also covers the initial fetch when from & to are in the url
  // and the case where delivery data is persisted through query params
  useEffect(() => {
    if (queryFromAndTo.from && queryFromAndTo.to) {
      dispatch(
        // only apply delivery query params, if rental offers delivery and delivery is set to true in the params
        getQuote(
          rentalHasDelivery && deliveryDataFromQuery && deliveryDataFromQuery.delivery
            ? { deliveryQueryParams: deliveryDataFromQuery }
            : {},
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryFromAndTo.from, queryFromAndTo.to]);

  const queryDatesSelection = useMemo(() => {
    if (queryFromAndTo.from && queryFromAndTo.to) {
      return {
        from: queryFromAndTo.from,
        to: queryFromAndTo.to,
      };
    }
  }, [queryFromAndTo.from, queryFromAndTo.to]);

  const quoteDatesSelection = useMemo(() => {
    if (quoteData?.from && quoteData?.to) {
      return {
        from: quoteData.from,
        to: quoteData.to,
      };
    }
  }, [quoteData?.from, quoteData?.to]);

  useEffect(() => {
    if (rentalId) {
      if (queryDatesSelection && !quoteDatesSelection) {
        dispatch(
          getSimilarRentals(rentalId.toString(), {
            limit: SIMILAR_RENTALS_LIMIT,
            from: queryDatesSelection.from,
            to: queryDatesSelection.to,
          }),
        );
      } else if (quoteDatesSelection && !queryDatesSelection) {
        dispatch(
          getSimilarRentals(rentalId.toString(), {
            limit: SIMILAR_RENTALS_LIMIT,
            from: quoteDatesSelection.from,
            to: quoteDatesSelection.to,
          }),
        );
      } else if (!quoteDatesSelection && !queryDatesSelection) {
        dispatch(
          getSimilarRentals(rentalId.toString(), {
            limit: SIMILAR_RENTALS_LIMIT,
          }),
        );
      }
    }
  }, [dispatch, queryDatesSelection, quoteDatesSelection, rentalId]);

  useEffect(() => {
    if (rentalId) {
      dispatch(getAvailability(rentalId.toString()));
    }
  }, [rentalId, dispatch]);

  // get a new quote when user logs in or out
  useEffect(() => {
    if (!quoteId || isFirstRender) {
      return;
    }
    dispatch(getQuote());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // Get new rentals when user's currency changes and ensure reviews/addons are available
  useEffect(() => {
    const hasChangedCurrency = listingCurrency !== userCurrency;

    if (rentalId) {
      if (hasChangedCurrency) {
        dispatch(getListing(rentalId.toString()));
      } else {
        // Only fetch these when currency doesn't change
        dispatch(getReviews(rentalId.toString()));
      }
      // Always re-fetch on client
      dispatch(getRentalItems(rentalId.toString()));
    }
  }, [rentalId, dispatch, userCurrency, listingCurrency]);

  useEffect(() => {
    if (!rentalId || !quoteBooking?.from || !quoteBooking?.to) return;
    dispatch(getInsuranceBundles(rentalId.toString(), quoteBooking.from, quoteBooking.to));
  }, [rentalId, dispatch, quoteBooking?.from, quoteBooking?.to, stationaryDelivery]);

  useEffect(() => {
    if (rentalId) {
      const recentlyViewedRentals: number[] =
        JSON.parse(getItemFromLocalStorage('recentlyViewedRentals') || '[]') || [];
      if (!recentlyViewedRentals.includes(rentalId)) {
        // ensure we don't push duplicates
        setItemInLocalStorage(
          'recentlyViewedRentals',
          JSON.stringify([rentalId, ...recentlyViewedRentals].slice(0, 5)),
        );
      }
    }
  }, [rentalId]);

  const handleClickLinkInHeader = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string,
  ) => {
    e.preventDefault();

    const element = document.getElementById(id);
    if (element) {
      const offset = window.innerWidth >= 768 ? STICKY_NAV_OFFSET : 32;
      window.scrollTo({
        behavior: 'smooth',
        top: element.getBoundingClientRect().top + window.pageYOffset - offset,
      });
    }
  };

  const handleNavItemClick = (anchor: string) => {
    if (rentalId) {
      trackEvent({
        event: 'Listing : Sticky header',
        rental_id: rentalId,
        anchor,
      });
    }
  };

  const handleImageClick = (index: number) => {
    if (listingData) {
      trackListingPageGalleryViewedEvent(listingData, 'listing', 1, index + 1, images.length);
    }
  };

  const navItems = [
    {
      label: intl.formatMessage({ defaultMessage: 'Overview', id: '9uOFF3' }),
      anchor: listingPageAnchors.OVERVIEW,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Details', id: 'Lv0zJu' }),
      anchor: listingPageAnchors.DETAILS,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Amenities', id: 'e38GOF' }),
      anchor: listingPageAnchors.AMENITIES,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Add-ons', id: '5lP56d' }),
      anchor: listingPageAnchors.ADDONS,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Reviews', id: 'dUxyza' }),
      anchor: listingPageAnchors.REVIEWS,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'FAQ', id: 'W8nHSd' }),
      anchor: listingPageAnchors.FAQ,
    },
  ];

  if (error) {
    return <ErrorPage statusCode={error.statusCode} message={error.message} />;
  }

  return (
    <>
      <Head />
      <main className="text-gray-900">
        <StickyNavContainer
          upperSectionRef={upperSectionRef}
          lowerSectionRef={lowerSectionRef}
          items={navItems}
          onClickItem={handleNavItemClick}
        />
        <div className="relative grid grid-cols-main container-main gap-x-default">
          <section
            ref={upperSectionRef}
            className="relative h-64 mb-6 col-container md:h-88 md:mb-8 lg:h-120 lg:mb-9">
            {isMobile ? (
              <Card.Carousel
                content={images.map((image, index) => {
                  const routerQuery = { ...router.query };

                  return (
                    <Link
                      href={{ pathname: imagesHref, query: routerQuery }}
                      onClick={() => handleImageClick(index)}
                      key={`${!isProduction() ? index : 'image'}-${image.url}`}>
                      <ResponsiveImage
                        className="object-cover w-full h-full transition-transform duration-300 ease-in-out transform"
                        src={image.url}
                        role="presentation"
                        cropMode="fill"
                        sourceSizes={['landscape768']}
                      />
                    </Link>
                  );
                })}
                size={ECardCarouselSize.Large}
                fullRadius
                swiperDynamicBullets
              />
            ) : (
              <PhotoGallery images={images} loading={isLoading} onClick={handleImageClick} />
            )}
            <HeaderActionsContainer isCampsite={isCampsite} isStay={isStay} />
          </section>
        </div>
        <div className="relative grid grid-cols-main container-main gap-x-default">
          <DeliveryModalProvider>
            <div className="mx-4 col-container lg:col-start-1 lg:m-0 lg:col-span-10">
              <div id={listingPageAnchors.OVERVIEW}>
                {/* This will always be first */}
                <SectionWithDivider>
                  <ListingHeaderContainer
                    onLinkClick={handleClickLinkInHeader}
                    divider={!shouldShowCampgroundBanner}
                    shouldShowProBadge={shouldShowProBadge}
                  />
                </SectionWithDivider>
              </div>
              {!isLoading && (
                <>
                  {shouldShowCampgroundBanner && <OutdoorsyStaysBanner />}
                  {finalTopOrder.map((orderSlug, index) => {
                    const shouldShowDivider =
                      !modulesWithoutDividers.includes(orderSlug) &&
                      index !== finalTopOrder.length - 1;
                    return (
                      <SectionWithDivider key={orderSlug} divider={shouldShowDivider}>
                        {TOP_MODULE_MAP[orderSlug]}
                      </SectionWithDivider>
                    );
                  })}
                </>
              )}
            </div>

            <BillSelection />
          </DeliveryModalProvider>
        </div>
        {!isLoading && (
          <>
            <div
              className="pt-16 pb-5 bg-canvas-100 md:pb-4 md:pt-13 lg:pt-16 lg:mt-16 md:mt-15"
              ref={lowerSectionRef}>
              <div className="relative grid grid-cols-main container-main gap-x-default">
                <div className="col-content">
                  <>
                    {finalBottomOrder.map((orderSlug, index) => {
                      return (
                        <SectionWithDivider
                          key={orderSlug}
                          divider={index !== finalBottomOrder.length - 1}>
                          {BOTTOM_MODULE_MAP[orderSlug]}
                        </SectionWithDivider>
                      );
                    })}
                  </>
                </div>
              </div>
            </div>
            {(!isMessagedPhoneUnverified || isPhoneVerified) && (
              <AskOwnerModalContainer isStay={isStay} />
            )}
            {isMessagedPhoneUnverified && !isPhoneVerified && <PhoneVerificationModalContainer />}
          </>
        )}
      </main>
    </>
  );
};

export default ListingIndex;
