import React from 'react';

import Button, {
  ButtonShape,
  ButtonSize,
  ButtonVariants,
} from '@/components/switchback/Button/Button';
import FloatingBox from '@/components/switchback/FloatingBox/FloatingBox';
import { CLOSE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';

import css from './BillSectionModal.module.css';

interface IProps {
  onCloseMobile?: () => void;
}

const BillSectionModal: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  onCloseMobile,
}) => {
  return (
    <FloatingBox className={css.floatingBox}>
      <div className={css.scrollContent}>
        <div className={css.closeButtonContainer}>
          <Button
            size={ButtonSize.small}
            shape={ButtonShape.circle}
            variant={ButtonVariants.lightPrimaryOutlined}
            onClick={onCloseMobile}>
            <Icon name={CLOSE} />
          </Button>
        </div>
        {children}
      </div>
    </FloatingBox>
  );
};

export default BillSectionModal;
