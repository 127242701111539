import React from 'react';
import { useSelector } from 'react-redux';

import { getIsCampsite } from '@/redux/selectors/listing/campsite';
import { getIsStay } from '@/redux/selectors/listing/listingHeader';
import { getRentalDetails } from '@/redux/selectors/listing/rentalDetails';

import LocationHighlights from '../LocationHighlights/LocationHighlights';
import { ViewCampgroundPage } from '../ViewCampgroundPage';
import RentalDescription from './RentalDescription';

const RentalDetailsContainer: React.FC = () => {
  const rentalDetails = useSelector(getRentalDetails);
  const isCampsite = useSelector(getIsCampsite);
  const isStay = useSelector(getIsStay);

  if (!rentalDetails) {
    return null;
  }

  const {
    description,
    description_other,
    description_recommendations,
    description_included,
    vehicleType,
  } = rentalDetails;

  return (
    <>
      <RentalDescription
        description={description}
        description_recommendations={description_recommendations}
        description_included={description_included}
        description_other={description_other}
        vehicleType={vehicleType}
        isCampsite={isCampsite}
        isStay={isStay}
      />

      {isCampsite ? <ViewCampgroundPage /> : isStay ? <LocationHighlights /> : null}
    </>
  );
};

export default RentalDetailsContainer;
