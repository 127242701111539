import { isObject } from '@/utility/objects';

import { ActiveOptimizelyDecision } from '.';

// Refine optimizely variable into a module order variable
const isOrderVar = (optVar: unknown): optVar is { module_order: string[] } => {
  if (isObject(optVar) && Array.isArray(optVar.module_order)) return true;
  return false;
};

// Default to existing legacy order in case we can't find certain orders
export const getModuleOrderFromOptimizely = (
  decision: ActiveOptimizelyDecision | undefined | null = undefined,
  // In case optimizely is down, hardcode the legacy order
  defaultOrder: Record<'topOrder' | 'bottomOrder', string[]> = {
    topOrder: [
      'vehicle-details',
      'features',
      'amenities',
      'house-rules',
      'towable-features',
      'education-content',
      'addons-section',
      'availability',
      'host-bio',
      'message-owner',
    ],
    bottomOrder: [
      'similar-rentals-carousel',
      'reviews',
      'question-answers',
      'location',
      'seo-content',
      'breadcrumbs',
    ],
  },
): { topOrder: string[]; bottomOrder: string[] } => {
  if (!decision?.variables) return defaultOrder;
  const bottomVar = decision.variables.bottom_module;
  const topVar = decision.variables.top_module;
  const bottomOrder = isOrderVar(bottomVar) ? bottomVar.module_order : defaultOrder.bottomOrder;
  const topOrder = isOrderVar(topVar) ? topVar.module_order : defaultOrder.topOrder;
  return { topOrder, bottomOrder };
};
