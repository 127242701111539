import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { ButtonVariants } from '@/components/switchback/Button/Button';
import Text from '@/components/switchback/Text/Text';

interface IProps extends React.HtmlHTMLAttributes<HTMLElement> {
  onClickButton: (data?: any) => any;
  showResponseTime?: boolean;
}

const AskHostButton: React.FC<IProps> = ({ onClickButton, showResponseTime = true }) => {
  return (
    <div className="md:flex md:items-center">
      <Button
        testid="ask-host-btn"
        className={'w-full md:w-auto md:justify-self-start md:whitespace-nowrap'}
        variant={ButtonVariants.primaryOutlined}
        onClick={onClickButton}>
        <FormattedMessage defaultMessage="Message host" id="dnrVg5" />
      </Button>
      {showResponseTime && (
        <Text
          type="paragraph"
          className="mt-2 text-xs text-center text-gray-500 md:inline md:ml-4 md:w-28 md:mt-0">
          <FormattedMessage defaultMessage="Usually answers in less than 24 hours" id="N2WBKJ" />
        </Text>
      )}
    </div>
  );
};

export default AskHostButton;
