import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { ICrumb } from '@/services/types/search/rentals/id';

export const getListingBreadcrumbs = createSelector<
  TRootState,
  ICrumb[] | undefined,
  ICrumb[] | undefined
>(
  state => state.listing.data?.breadcrumb?.crumbs,
  crumbs => crumbs,
);
