import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useRouter } from '@/hooks/useRouter';
import { updateQuoteDeliveryFromURL } from '@/redux/modules/delivery/quoteDelivery';
import { TAppDispatch } from '@/redux/rootReducer';
import { getParams } from '@/utility/queryParams';
import { createListingUrlObj } from '@/utility/routes';

// @TODO this will replace all the useEffect hooks in other BillSectionDeliveryContainer
// update delivery location in listing page from search page
export const useQueryDeliveryUpdate = () => {
  const dispatch: TAppDispatch = useDispatch();
  const router = useRouter();
  const { delivery, deliveryQuery, locationId, deliveryCenter, deliveryStationary, ...params } =
    getParams(router);
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      const deliveryLocation = await dispatch(
        updateQuoteDeliveryFromURL(
          delivery,
          deliveryQuery,
          locationId,
          deliveryCenter,
          deliveryStationary,
        ),
      );
      // remove the location params from url
      if (deliveryLocation) {
        router.replace(createListingUrlObj(params), undefined, { shallow: true });
      }
    }, 50);
    return () => clearTimeout(timeoutId);
    // disable dispatch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery, deliveryQuery, locationId, deliveryCenter, deliveryStationary, router]);
};
