import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import DatePickerModal from '@/components/switchback/DatePickerModal/DatePickerModal';
import Heading from '@/components/switchback/Heading/Heading';
import { breakpoint } from '@/constants/breakpoint';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import useWindowSize from '@/hooks/useWindowSize';
import { openMobileBillModule } from '@/redux/modules/listing';
import { getUnavailableDates } from '@/redux/selectors/listing/availability';
import { getListingMinimumDays } from '@/redux/selectors/listing/page';
import { getFromAndTo } from '@/redux/selectors/queryParams';
import { getQuoteData } from '@/redux/selectors/quote';
import { disableBodyScroll } from '@/utility/bodyScroll';
import formatDate from '@/utility/format-date';
import { createListingUrlObj, updateUrl } from '@/utility/routes';

const DatePicker = dynamic(() => import('@/components/switchback/DatePicker/DatePicker'), {
  ssr: false,
});

interface IDateRange {
  from?: Date;
  to?: Date;
}

interface IAvailabilityCalendarProps {
  show?: boolean;
  onClose?: () => void;
}

const AvailabilityCalendar: React.FC<IAvailabilityCalendarProps> = ({ show = false, onClose }) => {
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoint();
  const windowSize = useWindowSize();

  const queryFromAndTo = useSelector(getFromAndTo);
  const quoteData = useSelector(getQuoteData);
  const disabledDays = useSelector(getUnavailableDates);
  const minimumDays = useSelector(getListingMinimumDays);

  const mobileDatesPickerRef = useRef<HTMLDivElement | null>(null);

  // Query state for availability might have just start/end interval.
  const queryDatesSelection = useMemo(() => {
    if (queryFromAndTo.from || queryFromAndTo.to) {
      return {
        from: queryFromAndTo.from ? dayjs(queryFromAndTo.from).toDate() : undefined,
        to: queryFromAndTo.to ? dayjs(queryFromAndTo.to).toDate() : undefined,
      };
    }
  }, [queryFromAndTo.from, queryFromAndTo.to]);

  const quoteDatesSelection = useMemo(() => {
    if (quoteData?.from && quoteData?.to) {
      return {
        from: dayjs(quoteData.from).toDate(),
        to: dayjs(quoteData.to).toDate(),
      };
    }
  }, [quoteData?.from, quoteData?.to]);

  // We should prioritize query selection over quote. If selection is valid, quote
  // will get updated with the new selection and query will no longer store it.
  // When quote update/create fails, query will still keep the dates.
  const datesSelection = queryDatesSelection || quoteDatesSelection;

  const [initialMonth, setInitialMonth] = useState<Date | undefined>(datesSelection?.from);

  const handleChangeDates = ({ from, to }: IDateRange) => {
    updateUrl('push', createListingUrlObj, {
      paramsToOmit: ['quote'],
      paramsToOverride: {
        from: from && formatDate(from, 'YYYY-MM-DD'),
        to: to && formatDate(to, 'YYYY-MM-DD'),
      },
    }).then(() => {
      if (from && to && windowSize?.width && windowSize.width < breakpoint.lg) {
        if (isMobile) onClose?.();

        disableBodyScroll();
        dispatch(openMobileBillModule());
      }
    });
  };

  useEffect(() => {
    // Update initial month only once both, start/end interval are selected.
    if (datesSelection?.from && datesSelection?.to) {
      setInitialMonth(datesSelection.from);
    }
  }, [datesSelection?.from, datesSelection?.to]);

  if (isMobile) {
    return (
      <div ref={mobileDatesPickerRef}>
        <DatePickerModal
          show={show}
          onDismiss={() => onClose?.()}
          onSelectDate={(from: Date | undefined, to: Date | undefined) =>
            handleChangeDates({ from, to })
          }
          relativeTo={mobileDatesPickerRef.current}
          customHeader={
            <Heading level={2} className="pr-6 text-gray-900 highlight autoType800">
              <FormattedMessage defaultMessage="Availability" id="hOxIeP" />
            </Heading>
          }
        />
      </div>
    );
  }

  return (
    <div className="mt-8">
      <DatePicker
        range
        numberOfMonths={2}
        onChange={handleChangeDates}
        disabledDays={disabledDays}
        dateTo={datesSelection?.to}
        dateFrom={datesSelection?.from}
        toMonth={dayjs().add(2, 'year').toDate()}
        minimumDays={minimumDays}
        month={initialMonth}
        onMonthChange={setInitialMonth}
      />
    </div>
  );
};

export default AvailabilityCalendar;
