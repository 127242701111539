import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { closePhoneVerificationModal } from '@/redux/modules/auth';
import { TRootState } from '@/redux/rootReducer';
import getHost from '@/redux/selectors/listing/host';

import { PhoneVerificationForm } from './PhoneVerificationForm';
import PhoneVerificationModal from './PhoneVerificationModal';

const PhoneVerificationModalContainer: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const host = useSelector(getHost);
  const isOpen = useSelector<TRootState, boolean>(state => !!state.auth.phone.isModalOpen);
  const isVerifiedCode = useSelector<TRootState, boolean>(state => state.auth.phone.isVerifiedCode);

  const handleClose = useCallback(() => {
    dispatch(closePhoneVerificationModal());
  }, [dispatch]);

  React.useEffect(() => {
    if (isOpen && isVerifiedCode) handleClose();
  }, [isOpen, isVerifiedCode, handleClose]);

  if (!host) {
    return null;
  }

  const subTitle = intl.formatMessage(
    {
      defaultMessage: 'Once you’re verified, you can send {name} a question.',
      id: 'mzLzpU',
    },
    { name: host.name },
  );

  const title = intl.formatMessage({
    defaultMessage: 'We need to verify your phone',
    id: 'B5vkrt',
  });

  return (
    <PhoneVerificationModal
      title={title}
      subTitle={subTitle}
      isOpen={isOpen}
      onCloseModal={handleClose}>
      <PhoneVerificationForm />
    </PhoneVerificationModal>
  );
};

export default PhoneVerificationModalContainer;
