import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ClampModal from '@/components/route/listing/ClampModal/ClampModal';
import Heading from '@/components/switchback/Heading/Heading';
import Text from '@/components/switchback/Text/Text';
import { listingPageAnchors } from '@/constants/anchors';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { ICampsiteHouseRules } from '@/redux/selectors/listing/houseRules';

import FeatureItem from '../Features/FeatureItem';
import css from './CampgroundRules.module.css';

type TCampgroundRulesProps = {
  checkInRules?: ICampsiteHouseRules[];
  campgroundRules?: string;
};

export const CampgroundRules = ({ checkInRules, campgroundRules }: TCampgroundRulesProps) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();

  return (
    <div id={listingPageAnchors.RULES}>
      <Heading level={3} className="mb-5 font-bold md:mb-8 md:h3 text-700 semiHighlight">
        <FormattedMessage defaultMessage="Rules and policies" id="mcwwlw" />
      </Heading>

      <div className="mb-6 md:flex">
        <ul className={css.rules}>
          {checkInRules?.map(item => (
            <li key={item.title}>
              <FeatureItem
                bonfireIcon={item.icon}
                notAllowed={item.notAllowed}
                label={item.title}
              />
            </li>
          ))}
        </ul>
      </div>

      {campgroundRules && (
        <ClampModal
          ellipsis
          modalTitle={intl.formatMessage({ defaultMessage: 'Campground rules', id: 'sBCq8L' })}
          lineCount={isMobile ? 4 : 2}
          modalContent={
            <Text type="paragraph" className="mb-6 text-black">
              {campgroundRules}
            </Text>
          }
          showMoreText={intl.formatMessage({ defaultMessage: 'Show more', id: 'aWpBzj' })}>
          <Text type="paragraph" className="mb-6 text-secondary-base">
            {campgroundRules}
          </Text>
        </ClampModal>
      )}
    </div>
  );
};
