import { TCurrency } from '@/config/locales';
import apiRequest from '@/services/apiRequest';
import searchApiRequest from '@/services/searchApiRequest';
import { IReview } from '@/services/types/core/reviews/id';
import { IRentalResponse } from '@/services/types/search/rentals/id';
import { IUserResponse } from '@/services/types/search/users/id';
import { getCoreApi, getSearchApi } from '@/utility/getCoreApi';

import { IReportCard } from './types/owner';

export const getOwnerInfo = (slug: string): Promise<IUserResponse> => {
  const url = `${getSearchApi()}/users${Number.isNaN(Number(slug)) ? `?slug=${slug}` : `/${slug}`}`;
  return apiRequest<IUserResponse>({ url });
};

export const getOwnerRentals = (
  userId: number,
  limit = 8,
  currency: TCurrency,
  rentalCategory?: string,
): Promise<IRentalResponse | undefined> =>
  searchApiRequest<IRentalResponse>('rentals', {
    queryParams: {
      owner_id: userId,
      'page[limit]': limit,
      'filter[rental_category]': rentalCategory,
    },
    rejectOnError: true,
    currency,
  });

export const getOwnerReviews = async (
  userId: number,
  limit = 16,
  offset = 0,
): Promise<IReview[]> => {
  const url = `${getCoreApi()}/reviews?user_id=${userId}&offset=${offset}${
    limit > 0 ? `&limit=${limit}` : ''
  }`;

  const reviews = await apiRequest<IReview[]>({ url });

  return reviews || [];
};

export const getOwnerReportCard = (userId: number) => {
  const url = `${getCoreApi()}/users/${userId}/owner-report-card?include_all_rentals=true`;
  return apiRequest<IReportCard>(
    {
      url,
      method: 'GET',
    },
    true,
    true,
  );
};
