import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openCalendar, openMobileBillModule } from '@/redux/modules/listing';
import { getPricingInformation, getTotal } from '@/redux/selectors/listing/bill';
import { getCallToAction } from '@/redux/selectors/listing/callToAction';
import {
  getIsListingLoading,
  getIsListingPublished,
  getListingId,
} from '@/redux/selectors/listing/page';
import { getFromAndTo } from '@/redux/selectors/queryParams';
import { getIsListingAllowedByWhitelist, getNearbyRentalsURL } from '@/redux/selectors/stayStates';
import { trackEvent } from '@/services/track-event';
import { disableBodyScroll } from '@/utility/bodyScroll';

import BillSectionMobileBar from './BillSectionMobileBar';

const BillSectionMobileBarContainer: React.FC = () => {
  const dispatch = useDispatch();
  const callToAction = useSelector(getCallToAction);
  const { from, to } = useSelector(getFromAndTo);
  const isLoadingListing = useSelector(getIsListingLoading);
  const isPublished = useSelector(getIsListingPublished);
  const listingId = useSelector(getListingId);
  const isListingAllowedByWhitelist = useSelector(getIsListingAllowedByWhitelist);
  const inactiveHref = useSelector(getNearbyRentalsURL);
  const price = useSelector(getPricingInformation);
  const total = useSelector(getTotal);
  const hasDatesSelected = Boolean(from && to);

  const handleClickMobilaCta = useCallback(() => {
    dispatch(openMobileBillModule());
    disableBodyScroll();

    if (!hasDatesSelected) {
      trackEvent({
        event: 'Listing: check availability',
        rental_id: listingId,
      });
      dispatch(openCalendar());
    } else {
      trackEvent({
        event: 'Listing: view bill',
        rental_id: listingId,
        from: dayjs(from).toDate(),
        to: dayjs(to).toDate(),
        total,
      });
    }
  }, [hasDatesSelected, dispatch, from, to, listingId, total]);

  return (
    <BillSectionMobileBar
      loading={isLoadingListing}
      onRequestClick={handleClickMobilaCta}
      price={price}
      isPublished={isPublished}
      forceInactive={!isListingAllowedByWhitelist}
      inactiveHref={inactiveHref}
      cta={callToAction}
    />
  );
};

export default BillSectionMobileBarContainer;
