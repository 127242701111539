import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getCampsiteCampgroundAmenities } from '@/redux/selectors/listing/amenities';

import Amenities from './Amenities';

const CampsiteCampgroundAmenitiesContainer = () => {
  const intl = useIntl();

  const amenities = useSelector(getCampsiteCampgroundAmenities);
  const availableAmenities = amenities.list.filter(amenity => amenity.available);

  return (
    <Amenities
      items={amenities.list}
      categories={amenities.categories}
      modalTitle={intl.formatMessage({
        defaultMessage: 'Campground features',
        id: 'T4pVh/',
      })}
      showMoreText={intl.formatMessage(
        { defaultMessage: 'Show all {detailCount} features', id: '5acq69' },
        { detailCount: availableAmenities.length },
      )}
      header={intl.formatMessage({
        defaultMessage: 'Campground features',
        id: 'T4pVh/',
      })}
    />
  );
};

export default CampsiteCampgroundAmenitiesContainer;
