import React from 'react';

import Text from '@/components/switchback/Text/Text';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';

interface IProps {
  image?: {
    src: string;
    alt?: string;
  };
  title: string;
}

const BillSectionMobileHeader: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  image,
  title,
}) => {
  return (
    <div className="flex items-start mb-6 lg:hidden">
      {image && (
        <ResponsiveImage
          className="w-1/3 mr-4 rounded md:w-2/5 md:mr-5"
          src={image.src}
          alt={image.alt}
          sourceSizes={['landscape375', 'landscape768']}
          sizes="105px, (min-width: 768px) 268px"
        />
      )}
      <div>
        <Text
          className="w-3/4 mb-2 text-gray-900 highlight autoType600 md:autoType800"
          type="block">
          {title}
        </Text>
        {children}
      </div>
    </div>
  );
};

export default BillSectionMobileHeader;
