import React from 'react';
import { useIntl } from 'react-intl';

import FormattedPlaintext from '@/components/switchback/FormattedPlaintext/FormattedPlaintext';
import Heading from '@/components/switchback/Heading/Heading';
import { listingPageAnchors } from '@/constants/anchors';

import ClampModal from '../ClampModal/ClampModal';
import css from './RentalDescription.module.css';

interface IRentalDetails {
  description?: string;
  description_included?: string;
  description_other?: string;
  description_recommendations?: string;
  vehicleType?: string;
  isCampsite?: boolean;
  isStay?: boolean;
}

const RentalDetails: React.FC<IRentalDetails> = ({
  description,
  description_included,
  description_other,
  description_recommendations,
  vehicleType,
  isCampsite,
  isStay,
}) => {
  const intl = useIntl();

  const hasAnyDesc = !!(
    description_recommendations ||
    description_included ||
    description_other ||
    description
  );

  const modalTitle =
    isStay || isCampsite
      ? intl.formatMessage({ defaultMessage: 'About this stay', id: '3GSyNM' })
      : intl.formatMessage({
          defaultMessage: 'About this RV',
          id: 'twZz+9',
        });

  const headerCss = `mb-2 text-gray-900 autoType600 highlight ${css.paragraph}`;

  return (
    <div data-testid="vehicle-details-module" id={listingPageAnchors.DETAILS}>
      {vehicleType && !isCampsite && !isStay && (
        <Heading level={5} className="hidden mb-2 h4 md:block">
          {vehicleType}
        </Heading>
      )}
      {!!hasAnyDesc && (
        <ClampModal lineCount={5} modalTitle={modalTitle} ellipsis>
          <div className="text-gray-900 semiHighlight">
            {!!description && <FormattedPlaintext text={description} paragraphClasses="mb-4" />}
            {!!description_included && (
              <span className="mt-6 mb-4">
                <span className={headerCss}>
                  {intl.formatMessage({ defaultMessage: "What's included", id: 'oESc06' })}
                </span>
                <FormattedPlaintext text={description_included} paragraphClasses="mb-4" />
              </span>
            )}
            {!!description_recommendations && (
              <span className="mt-6 mb-4">
                <span className={headerCss}>
                  {intl.formatMessage({ defaultMessage: 'Recommendations', id: 'EnTkxu' })}
                </span>
                <FormattedPlaintext text={description_recommendations} paragraphClasses="mb-4" />
              </span>
            )}
            {!!description_other && (
              <span className="mt-6 mb-4">
                <span className={headerCss}>
                  {intl.formatMessage({ defaultMessage: 'Other things to note', id: 'zxQcuq' })}
                </span>
                <FormattedPlaintext text={description_other} paragraphClasses="mb-4" />
              </span>
            )}
          </div>
        </ClampModal>
      )}
    </div>
  );
};

export default RentalDetails;
