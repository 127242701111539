import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from '@/hooks/usePrevious';
import { setTotalAddons } from '@/redux/modules/addons';
import {
  getAddons,
  getAddonsIsLoading,
  getAddonsWithQuantitySelected,
  getHasAddons,
} from '@/redux/selectors/listing/addons';
import { getQuoteData, getQuoteIsLoading } from '@/redux/selectors/quote';

import AddOnsSection from './AddOnsSection';

const AddOnSectionContainer: React.FC = () => {
  const dispatch = useDispatch();
  const hasAddons = useSelector(getHasAddons);
  const selectedAddons = useSelector(getAddons);
  const isLoadingAddons = useSelector(getAddonsIsLoading);
  const isLoadingQuote = useSelector(getQuoteIsLoading);
  const availableItems = useSelector(getAddonsWithQuantitySelected);
  const quote = useSelector(getQuoteData);
  const previousQuoteId = usePrevious(quote?.id);

  useEffect(() => {
    if (selectedAddons && !previousQuoteId && quote && quote.id && !quote.addons) {
      dispatch(setTotalAddons());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddons, previousQuoteId, quote?.id]);

  if (!hasAddons) {
    return null;
  }

  return <AddOnsSection loading={isLoadingAddons || isLoadingQuote} addons={availableItems} />;
};

export default AddOnSectionContainer;
