import React from 'react';

import { CARET_SMALL } from '@/components/switchback/Icon/assets';
import Icon, { IconSize } from '@/components/switchback/Icon/IconComponent';
import Link from '@/components/switchback/Link/Link';

import css from './Breadcrumbs.module.css';

interface Crumb {
  title: string;
  url: string;
}

interface BreadcrumbProps {
  breadcrumbs: Crumb[];
  'data-testid'?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  breadcrumbs,
  ['data-testid']: testid = 'breadcrumbs',
}) => (
  <ol className="flex flex-row flex-wrap" data-testid={testid}>
    {breadcrumbs.map(({ title, url }, index) => (
      <li className="autoType300" key={`breadcrumb-${title}`}>
        <Link className={css.item} href={url}>
          {title}
        </Link>
        {index !== breadcrumbs.length - 1 && (
          <Icon
            aria-hidden="true"
            size={IconSize.large}
            className={css.separator}
            name={CARET_SMALL}
          />
        )}
      </li>
    ))}
  </ol>
);

export default Breadcrumb;
