import React from 'react';

interface IFeedbackScreenProps {
  title: string;
  description: string | React.ReactElement;
}

const FeedbackScreen: React.FC<IFeedbackScreenProps> = ({ title, description }) => {
  return (
    <div
      aria-live="assertive"
      data-testid="feedback-screen"
      className="absolute inset-0 flex flex-col items-center justify-center bg-white">
      <div className="w-56 text-center md:w-full">
        <p className="highlight autoType800">{title}</p>
        {typeof description === 'string' ? (
          <p className="mt-4 text-gray-500 text autoType300">{description}</p>
        ) : (
          <div className="mt-4 text-gray-500 text autoType300">{description}</div>
        )}
      </div>
    </div>
  );
};

export default FeedbackScreen;
