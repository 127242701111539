import React from 'react';
import { useIntl } from 'react-intl';

import Modal from '@/components/switchback/Modal';

export enum FIELDS {
  MESSAGE = 'message',
}
interface IProps extends React.HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  onCloseModal: () => any;
  isStay?: boolean;
}

const AskHostModal: React.FC<IProps> = ({ children, isStay, isOpen, onCloseModal }) => {
  const intl = useIntl();
  const title = isStay
    ? intl.formatMessage({
        defaultMessage: 'Message the host of this stay',
        id: 'lWInKX',
      })
    : intl.formatMessage({
        defaultMessage: 'Message the host of this RV',
        id: 'ZwJub3',
      });

  return (
    <Modal title={title} open={isOpen} data-testid="ask-host-modal" onClose={onCloseModal}>
      {children}
    </Modal>
  );
};

export default AskHostModal;
