import { useSelector } from 'react-redux';

import { getIsCampground, getIsStay } from '@/redux/selectors/listing/listingHeader';
import { getListingData, getListingId } from '@/redux/selectors/listing/page';
import { getQuoteData } from '@/redux/selectors/quote';
import { trackListingAvailabilityCheckedEvent } from '@/services/analytics/listings';
import { ERentalType } from '@/services/analytics/types';
import { trackEvent } from '@/services/track-event';

export const useTrackListingAvailability = () => {
  const rentalId = useSelector(getListingId);
  const listingData = useSelector(getListingData);
  const quoteData = useSelector(getQuoteData);
  const isCampground = useSelector(getIsCampground);
  const isStay = useSelector(getIsStay);

  return (from: Date, to: Date) => {
    if (!rentalId) return;

    try {
      trackEvent({
        event: 'Listing : Check availability',
        rental_id: rentalId,
        departure_date: from,
        return_date: to,
      });

      if (listingData && quoteData) {
        trackListingAvailabilityCheckedEvent({
          rentalID: rentalId,
          rentalType: (() => {
            if (isCampground) {
              return listingData.campsite_category?.category_type || ERentalType.CAMPSITE;
            }

            if (isStay) {
              return ERentalType.STAY;
            }

            return ERentalType.RV;
          })() as ERentalType,
          isCampground,
          reviewsCount: listingData.reviews_num,
          reviewsAverage: listingData.average_reviews?.score,
          isInstabook: listingData.instant_book,
          pricePerNight: listingData.price_per_day / 100,
          basePricePerNight: quoteData.original_day_price / 100,
          discountPercentOff:
            Math.floor(
              (quoteData.original_day_price - quoteData.calculated_day_price) /
                quoteData.calculated_day_price,
            ) * 100,
          departureDate: parseInt((from.getTime() / 1000).toFixed(0)),
          returnDate: parseInt((to.getTime() / 1000).toFixed(0)),
          totalNights: quoteData.duration,
        });
      }
    } catch {
      // Reporting failure should not block date selection
    }
  };
};
