import dayjs from 'dayjs';
import React, { RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from '@/hooks/useRouter';
import { useTrackListingAvailability } from '@/hooks/useTrackListingAvailability';
import { clearQuote } from '@/redux/modules/quote';
import { getIsUnavailable, getUnavailableDates } from '@/redux/selectors/listing/availability';
import { getCheckoutURL, getPricingInformation } from '@/redux/selectors/listing/bill';
import { getCallToAction } from '@/redux/selectors/listing/callToAction';
import { getIsCampground } from '@/redux/selectors/listing/listingHeader';
import { getListingMinimumDays } from '@/redux/selectors/listing/page';
import { getFromAndTo } from '@/redux/selectors/queryParams';
import { getQuoteError } from '@/redux/selectors/quote';
import formatDate from '@/utility/format-date';
import { getParams } from '@/utility/queryParams';
import { createListingUrlObj } from '@/utility/routes';

import StickyNav from './StickyNav';

interface IProps {
  upperSectionRef: RefObject<HTMLElement>;
  lowerSectionRef: RefObject<HTMLDivElement>;
  items: {
    label: string;
    anchor: string;
  }[];
  onClickItem?: (anchor: string) => void;
}

const StickyNavContainer: React.FC<IProps> = ({
  upperSectionRef,
  lowerSectionRef,
  items,
  onClickItem,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const queryParams = getParams(router);
  const { from, to } = useSelector(getFromAndTo);
  const checkoutURL = useSelector(getCheckoutURL);
  const isUnavailable = useSelector(getIsUnavailable);
  const minimumDays = useSelector(getListingMinimumDays);
  const price = useSelector(getPricingInformation);
  const quoteError = useSelector(getQuoteError);
  const callToAction = useSelector(getCallToAction);
  const disabledDays = useSelector(getUnavailableDates);
  const isCampground = useSelector(getIsCampground);

  const dateTo = price.to || to ? dayjs(price.to || to).toDate() : undefined;
  const dateFrom = price.from || from ? dayjs(price.from || from).toDate() : undefined;

  const trackListingAvailabilityEvent = useTrackListingAvailability();

  const handleSelectDate = (from?: Date, to?: Date) => {
    // Remove quote queryParam so we can build a new quote request
    const { quote: _unused, ...restQueryParams } = queryParams;

    router.push(
      createListingUrlObj({
        ...restQueryParams,
        from: from && formatDate(from, 'YYYY-MM-DD'),
        to: to && formatDate(to, 'YYYY-MM-DD'),
      }),
      undefined,
      { shallow: true },
    );

    if (from && to) {
      trackListingAvailabilityEvent(from, to);
    }

    if (!from && !to) {
      dispatch(clearQuote());
    }
  };

  const handleClickRequestToBook = () => {
    // TODO: use client-side transition once checkout A/B test is finished
    window.location.href = checkoutURL;
    // router.push(checkoutURL);
  };

  return (
    <StickyNav
      isCampground={isCampground}
      billPrice={price}
      ctaLabel={callToAction}
      checkoutURL={checkoutURL}
      dateTo={dateTo}
      dateFrom={dateFrom}
      disabledDays={disabledDays}
      hasInvalidDates={Boolean(isUnavailable || quoteError)}
      items={items}
      lowerSectionRef={lowerSectionRef}
      minimumDays={minimumDays}
      upperSectionRef={upperSectionRef}
      onClickRequestToBook={handleClickRequestToBook}
      onSelectdate={handleSelectDate}
      onClickAnchor={(anchor: string) => onClickItem && onClickItem(anchor)}
    />
  );
};

export default StickyNavContainer;
