import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import SectionWithDivider from '@/components/route/listing/SectionWithDivider/SectionWithDivider';
import Accordion from '@/components/switchback/Accordion/Accordion';
import Heading from '@/components/switchback/Heading/Heading';
import Link, { LinkVariants } from '@/components/switchback/Link/Link';
import Text from '@/components/switchback/Text/Text';
import { ISeoContentRow, ISeoContentRows } from '@/constants/seoContent';

interface IProps {
  data?: ISeoContentRows;
}

const SeoContent: React.FC<IProps> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  if (!data) {
    return null;
  }

  const content = Object.values(data).filter(row => row.content);

  const handleToggle = (state: boolean) => {
    setIsOpen(state);
  };

  return (
    <>
      {content.map((row: ISeoContentRow, index) => (
        <SectionWithDivider
          divider={index !== content.length - 1}
          key={row.title}
          data-testid="seo-accordion-module">
          <Accordion
            id={`seo-accordion-${index}`}
            data-testid={isOpen ? 'seo-accordion-open' : 'seo-accordion-closed'}
            onToggle={handleToggle}
            label={
              <Heading
                level={2}
                className="text-2xl md:text-3xl highlight"
                data-testid="seo-accordion-title">
                {row.title}
              </Heading>
            }>
            <ul className="grid grid-cols-6 gap-x-default md:grid-cols-10 lg:grid-cols-16">
              {row.content?.map(item => (
                <li key={item.title} className="block mb-3 col-span-3">
                  <Link
                    className="text-sm text-gray-800"
                    variant={LinkVariants.underlineHover}
                    href={item.url}
                    target="_blank">
                    {item.title}
                  </Link>
                  {!!item.listing_count && (
                    <Text className="text-sm text-gray-800" type="paragraph">
                      {intl.formatMessage(
                        {
                          defaultMessage: '{count, plural,one {# RV rental} other {# RV rentals}}',
                          id: '03gRRd',
                        },
                        { count: item.listing_count },
                      )}
                    </Text>
                  )}
                </li>
              ))}
            </ul>
          </Accordion>
        </SectionWithDivider>
      ))}
    </>
  );
};

export default SeoContent;
