import React from 'react';

import { IAddOn } from '@/redux/selectors/listing/addons';

interface IAddOnProps {
  addon: IAddOn;
  isModal?: boolean;
}

const AddOn: React.FC<IAddOnProps> = ({ addon, isModal }) => (
  <div className="flex justify-between mb-6">
    <div className="max-w-[492px]">
      <div className="text-base md:text-lg highlight">{addon.name}</div>
      <div
        className={`md:text-base text-xs semiHighlight ${
          isModal ? 'text-primary-base' : 'text-secondary-base'
        }`}>
        {addon.description}
      </div>
    </div>
    <div className="flex md:block">
      <div className="text-base text-right md:text-lg highlight">{addon.price}</div>
      {addon.priceInfo && (
        <div className="mt-1 text-xs text-right semiHighlight md:mt-0">/{addon.priceInfo}</div>
      )}
    </div>
  </div>
);

export default AddOn;
