import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addToFavorites, IFavorite, removeFromFavorites } from '@/redux/modules/favorites';
import { TRootState } from '@/redux/rootReducer';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { getQuoteEventData } from '@/redux/selectors/listing/bill';
import {
  getListingData,
  getListingDescription,
  getListingSocialImage,
  getListingTitle,
} from '@/redux/selectors/listing/page';
import {
  trackListingPageGalleryViewedEvent,
  trackListingSavedEvent,
  trackListingUnsavedEvent,
} from '@/services/analytics/listings';
import { trackEvent } from '@/services/track-event';

import HeaderActions from './HeaderActions';

type THeaderActionsContainerProps = {
  isCampsite?: boolean;
  isStay?: boolean;
};

const HeaderActionsContainer: React.FC<THeaderActionsContainerProps> = ({ isCampsite, isStay }) => {
  const description = useSelector(getListingDescription);
  const listingData = useSelector(getListingData);
  const socialImage = useSelector(getListingSocialImage);
  const quoteEventData = useSelector(getQuoteEventData);
  const title = useSelector(getListingTitle);
  const favorites = useSelector(getUserFavorites);
  const dispatch = useDispatch();
  const isLoading = useSelector<TRootState, boolean>(state => Boolean(state.listing.isLoading));
  const id = listingData?.id;
  const photoCount: number = listingData?.images.length ?? 1;

  const isFavorite =
    favorites && favorites.data.some((favorite: IFavorite) => favorite.rental_id === id);

  const isOutdoorsyStay = quoteEventData.isOutdoorsyStay;

  const onSave = () => {
    if (typeof id === 'undefined') {
      return;
    }

    if (isFavorite) {
      dispatch(removeFromFavorites(id));
      if (listingData) {
        trackListingUnsavedEvent(listingData, 1, 'listing', isOutdoorsyStay);
      }
    } else {
      dispatch(addToFavorites(id));
      if (listingData) {
        trackListingSavedEvent(listingData, 1, 'listing', isOutdoorsyStay);
      }
    }
    trackEvent({
      event: 'Listing : Save',
      action: 'save',
      rental_id: id,
    });
  };

  const onShare = () => {
    if (id) {
      trackEvent({
        event: 'Listing : Share',
        rental_id: id,
      });
    }
  };

  const onExploreThisVehicle = () => {
    if (listingData && listingData.images.length) {
      trackListingPageGalleryViewedEvent(
        listingData,
        'explore_this_vehicle',
        1,
        1,
        listingData.images.length,
      );
    }
  };

  if (!listingData) {
    return null;
  }

  return (
    <HeaderActions
      isCampsite={isCampsite}
      isStay={isStay}
      loading={isLoading}
      listingData={listingData}
      onSave={onSave}
      onShare={onShare}
      onExploreThisVehicle={onExploreThisVehicle}
      isFavorite={isFavorite}
      social={{ description, socialImage, title }}
      photoCount={photoCount}
      isOutdoorsyStay={isOutdoorsyStay}
    />
  );
};

export default HeaderActionsContainer;
