import React from 'react';
import { useSelector } from 'react-redux';

import HostBio from '@/components/route/listing/HostBio/HostBio';
import { TRootState } from '@/redux/rootReducer';
import getHost from '@/redux/selectors/listing/host';

const HostBioContainer = () => {
  const host = useSelector(getHost);
  const hostProfileUrl = host ? `/pro/${host.slug}` : '';

  const isLoading = useSelector<TRootState, boolean>(state => Boolean(state.listing.isLoading));

  return (
    <HostBio
      loading={isLoading}
      avgResponseTime={host?.responseTime}
      hostProfileUrl={hostProfileUrl}
      isDealer={host?.dealer}
      memberSince={host?.memberSince}
      name={host?.name || ''}
      photoUrl={host?.avatarUrl}
      avatarName={host?.name || ''}>
      {host?.description || ''}
    </HostBio>
  );
};

export default HostBioContainer;
