import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { phoneValidator } from '@/constants/validationRules';
import {
  postCodeVerification,
  postTextVerification,
  textVerificationReset,
} from '@/redux/modules/auth';
import { textVerificationSetError } from '@/redux/modules/auth/phoneVerification';

import CodeInput from '../switchback/CodeInput';
import Link from '../switchback/Link/Link';
import TextInput from '../switchback/TextInput';
import { TFieldErrors } from './types/TFieldErrors';

interface IPhoneVerificationForm {
  phone: string;
}

const PhoneVerificationForm: React.FC = () => {
  const intl = useIntl();

  const phoneAuth = useSelector((state: any) => state.auth?.phone);
  const savedPhone = useSelector((state: any) => state.auth?.user?.phone);

  const [phoneNumber, setPhoneNumber] = useState(savedPhone);
  const [allowCodeSubmit, setAllowCodeSubmit] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<IPhoneVerificationForm>();

  if (phoneAuth?.error?.error && !errors.phone) {
    setError('phone', {
      type: 'pattern',
    });
  }

  if (!phoneAuth?.error?.error && errors.phone) {
    clearErrors('phone');
  }

  const dispatch = useDispatch();

  const fieldErrors: TFieldErrors<keyof IPhoneVerificationForm> = {
    phone: {
      required: intl.formatMessage({
        defaultMessage: 'Phone number is required.',
        id: 'Il7p07',
      }),
      pattern: intl.formatMessage({
        defaultMessage: 'Please enter a valid phone number.',
        id: 'H+RqPP',
      }),
    },
  };
  const fieldLabels = {
    link: {
      change: intl.formatMessage({ defaultMessage: 'Change phone number', id: '+MHabZ' }),
      resendPrefix: intl.formatMessage({ defaultMessage: 'Didn’t get it?', id: 'ttkc4T' }),
      resend: intl.formatMessage({ defaultMessage: 'Resend', id: 'IXycMo' }),
    },
    input: {
      code: intl.formatMessage({
        defaultMessage: 'Enter your 4 digit verification code',
        id: 'i7pyvd',
      }),
      phone: intl.formatMessage({ defaultMessage: 'Phone number', id: 'jdJhOL' }),
      helper: intl.formatMessage({
        defaultMessage: 'You’ll receive a 4 digit verification code.',
        id: 'A5h/s2',
      }),
    },
    button: {
      step1: intl.formatMessage({ defaultMessage: 'Send Verification Code', id: 'Cxfsf1' }),
      step2: intl.formatMessage({ defaultMessage: 'Submit', id: 'wSZR47' }),
    },
  };

  //#region CODE SUBMIT
  const handleEdit = useCallback(() => {
    dispatch(textVerificationReset());
  }, [dispatch]);

  const onCodeIncomplete = useCallback(() => {
    setAllowCodeSubmit(false);
    dispatch(textVerificationSetError(''));
  }, [dispatch]);

  const onCodeComplete = (code: string) => {
    setAllowCodeSubmit(true);
    setVerificationCode(code);
  };

  const onSubmitCode = useCallback(() => {
    dispatch(postCodeVerification(verificationCode, phoneNumber));
  }, [verificationCode, phoneNumber, dispatch]);

  //TODO: add a spinner + confirmation on click of resend
  const resendText = useCallback(() => {
    dispatch(postTextVerification(phoneNumber, true));
  }, [phoneNumber, dispatch]);

  const restartVerification = useCallback(() => {
    dispatch(textVerificationReset());
  }, [dispatch]);
  //#endregion

  const onSubmit = useCallback<(data: IPhoneVerificationForm) => void>(
    ({ phone }) => {
      setPhoneNumber(phone);
      return dispatch(postTextVerification(phone));
    },
    [dispatch],
  );

  if (!phoneAuth.isModalOpen) return null;

  if (!(phoneAuth.isTexted || phoneAuth.isResending)) {
    return (
      <form className="flex flex-col w-full pt-2  bg-white" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          defaultValue={phoneNumber}
          id="phone"
          type="tel"
          autoComplete="tel"
          label={fieldLabels.input.phone}
          aria-label={fieldLabels.input.phone}
          aria-invalid={!!errors.phone}
          aria-required="true"
          aria-autocomplete="inline"
          aria-errormessage={errors.phone?.message}
          onFocus={restartVerification}
          error={
            errors.phone && (fieldErrors.phone[errors.phone.type] || fieldErrors.phone.pattern)
          }
          {...register('phone', phoneValidator)}
        />
        <p className="my-3 text-gray-500">{fieldLabels.input.helper}</p>

        <hr className="h-px my-8 bg-gray-100 border-0" />
        <div className="flex justify-end ">
          <Button
            variant={EButtonColorVariant.Primary}
            label={fieldLabels.button.step1}
            disabled={phoneAuth.isTexting}
          />
        </div>
      </form>
    );
  }
  // STEP 2 : CONFIRM CODE
  return (
    <div className="flex flex-col w-full pt-2  bg-white" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        defaultValue={phoneNumber}
        id="phone"
        name="phone"
        disabled
        className={'bg-gray-200'}
        label={fieldLabels.input.phone}
        aria-label={fieldLabels.input.phone}
      />

      <Link className="mt-1 text-sm text-left autoType400" onClick={handleEdit}>
        {fieldLabels.link.change}
      </Link>
      <p className="mt-6 mb-2 font-medium text-gray-900">{fieldLabels.input.code}</p>
      <CodeInput onFilled={onCodeComplete} onPartial={onCodeIncomplete} />
      {phoneAuth?.error?.error && (
        <div className="w-full mt-2 text-xs text-red-800">{phoneAuth?.error?.error}</div>
      )}

      <hr className="h-px my-8 bg-gray-100 border-0" />
      <div className="flex items-center justify-between">
        <div>
          {fieldLabels.link.resendPrefix}
          <Link className="ml-2 text-left autoType400" onClick={resendText}>
            {fieldLabels.link.resend}
          </Link>
        </div>
        <Button
          variant={EButtonColorVariant.Primary}
          label={fieldLabels.button.step2}
          disabled={!allowCodeSubmit}
          onClick={onSubmitCode}
        />
      </div>
    </div>
  );
};

export { PhoneVerificationForm };
