import React from 'react';

import Modal from '@/components/switchback/Modal';
import { ModalSize } from '@/components/switchback/Modal/Modal';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onCloseModal: () => any;
}

const PhoneVerificationModal: React.FC<IProps> = ({
  title,
  subTitle,
  isOpen,
  onCloseModal,
  children,
}) => {
  return (
    <Modal
      size={ModalSize.medium}
      title={title}
      open={isOpen}
      data-testid="ask-host-modal"
      onClose={onCloseModal}>
      <p className="my-4 text-gray-900">{subTitle}</p>
      {children}
    </Modal>
  );
};

export default PhoneVerificationModal;
