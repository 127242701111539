import React from 'react';
import { useSelector } from 'react-redux';

import { BillModuleInsurance as BaseBillModuleInsurance } from '@/components/bill/BillModuleInsurance';
import { getInsuranceBundles } from '@/redux/selectors/listing/insuranceBundles';
import { getListingId } from '@/redux/selectors/listing/page';
import { trackEvent } from '@/services/track-event';

type TBillModuleInsuranceProps = {
  updateStep?: () => void;
  completed?: boolean;
};

export const BillModuleInsurance: React.FC<TBillModuleInsuranceProps> = ({
  updateStep,
  completed,
}) => {
  const rentalId = useSelector(getListingId);
  const insuranceBundles = useSelector(getInsuranceBundles);

  const handleUpdateStep = async (bundleId?: string) => {
    if (rentalId && bundleId) {
      trackEvent({
        event: 'Listing : Bill insurance coverage',
        insurance_tier: insuranceBundles.find(b => b.id === bundleId)?.tier,
        rental_id: rentalId,
      });
    }

    updateStep?.();
  };

  return <BaseBillModuleInsurance updateStep={handleUpdateStep} completed={completed} />;
};
