import React from 'react';
import { useSelector } from 'react-redux';

import { FAQ_LIMIT } from '@/constants/listing';
import { getQuestionAnswers } from '@/redux/selectors/listing/questionAnswers';

import QuestionAnswers from './QuestionAnswers';

const QuestionAnswersContainer: React.FC = () => {
  const { total, questionAnswers } = useSelector(getQuestionAnswers);

  if (!total || !questionAnswers?.length) {
    return null;
  }

  return <QuestionAnswers total={total} questionAnswers={questionAnswers} showLimit={FAQ_LIMIT} />;
};

export default QuestionAnswersContainer;
