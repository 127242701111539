import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import AskHostForm from '@/components/route/listing/AskHostForm/AskHostForm';
import AskHostModal from '@/components/route/listing/AskHostModal/AskHostModal';
import { useRouter } from '@/hooks/useRouter';
import { fetchUser } from '@/redux/modules/auth/userMini';
import { askHost, closeAskHostModal, IState as IListingState } from '@/redux/modules/listing';
import { TRootState } from '@/redux/rootReducer';
import {
  getIsPhoneVerified,
  hasMessagedPhoneUnverified,
} from '@/redux/selectors/auth/verifications';
import getHost from '@/redux/selectors/listing/host';
import { trackMessageSentEvent } from '@/services/analytics/listings';
import { trackEvent } from '@/services/track-event';
import { isSSR } from '@/utility/isSSR';
import { parseRentalIdFromSlug } from '@/utility/routes';
import {
  EVENT_NAME_LOAD_USER,
  EVENT_SESSION_NAME,
  isAuthenticated as getIsAuthenticated,
  openSessionModal,
} from '@/utility/session';

export type FormData = {
  message: string;
};

type TAskHostModalContainerProps = {
  isStay?: boolean;
};

const AskHostModalContainer: React.FC<TAskHostModalContainerProps> = ({ isStay }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const form = useForm<FormData>({ mode: 'onSubmit' });
  const formId = 'ask-host-form';
  const host = useSelector(getHost);
  const {
    askHostError,
    hasAskHostSucceeded,
    isAskingHost: isLoading,
    isAskHostModalOpen: isOpen,
  } = useSelector<TRootState, IListingState>(state => state.listing);
  const rentalId = parseRentalIdFromSlug(router.asPath);
  const [isAuthenticated, setIsAuthenticated] = useState(getIsAuthenticated());
  const [formValue, setFormValue] = useState('');
  const hasMessagedUnverified = useSelector(hasMessagedPhoneUnverified);
  const hasVerifiedPhone = useSelector(getIsPhoneVerified);

  const handleSubmit = async (data: any) => {
    dispatch(askHost(data.message));

    trackEvent({
      event: 'Started a conversation',
      rentalId,
      ownerId: host?.id,
      rental_category: isStay ? 'stay' : 'rv',
    });
    trackMessageSentEvent({
      messageCharacterCount: (data.message as string).length,
      recipientUserID: host?.id,
      sendingUserActor: 'renter',
      rentalID: Number(rentalId),
      isConversationStarter: true,
      isDealer: host?.dealer ?? undefined,
    });
  };

  const handleClose = () => {
    dispatch(closeAskHostModal());
    form.reset();
    if (!hasMessagedUnverified && !hasVerifiedPhone) {
      dispatch(fetchUser());
    }
  };

  const handleSignup = () => {
    openSessionModal('signup');
  };

  const handleLogin = () => {
    openSessionModal('login');
  };

  useEffect(() => {
    function authenticate(event: any) {
      if (event?.detail?.name === EVENT_NAME_LOAD_USER) {
        setIsAuthenticated(true);
      }
    }

    !isSSR() && window.addEventListener(EVENT_SESSION_NAME, authenticate);

    return () => {
      !isSSR() && window.removeEventListener(EVENT_SESSION_NAME, authenticate);
    };
  }, []);

  useEffect(() => {
    setIsAuthenticated(getIsAuthenticated());
  }, [isOpen]);

  if (!host) {
    return null;
  }

  return (
    <AskHostModal isOpen={isOpen} onCloseModal={handleClose} isStay={isStay}>
      <AskHostForm
        isStay={isStay}
        avgResponseTime={host.responseTime}
        hasSucceeded={hasAskHostSucceeded}
        error={askHostError}
        form={form}
        formId={formId}
        hostName={host.name}
        loading={isLoading}
        isAuthenticated={isAuthenticated}
        onSubmit={handleSubmit}
        onClickSignup={handleSignup}
        onClickLogin={handleLogin}
        onChange={e => {
          setFormValue(e.target.value.trim());
        }}
        value={formValue}
      />
    </AskHostModal>
  );
};

export default AskHostModalContainer;
