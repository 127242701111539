import React from 'react';
import { FormattedMessage } from 'react-intl';
import { renderMarkup } from 'react-render-markup';

import Divider from '@/components/switchback/Divider/Divider';
import { IQuestionAnswers } from '@/services/types/faq/faqs';

export const FAQ = (props: { idx: number; faq: IQuestionAnswers }) => (
  <div data-testid="faq">
    {Boolean(props.idx) && <Divider className="my-4 lg:my-6" />}
    <strong className="flex">
      <span className="mr-1 text-gray-800">
        <FormattedMessage defaultMessage="Q." id="O20E4x" description="FAQ questions" />
      </span>
      {renderMarkup(props.faq.question)}
    </strong>
    <div className="flex mt-5 text-gray-500 lg:mt-4 font-text text-500 leading-400">
      <span className="mr-1 text-gray-800">
        <FormattedMessage defaultMessage="A." id="/wrAoe" description="FAQ answer" />
      </span>
      <div className="flex flex-col">{renderMarkup(props.faq.answer)}</div>
    </div>
  </div>
);
