import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getCampgroundLocationDetailsAmenityList } from '@/redux/selectors/listing/amenities';
import { getIsCampsite } from '@/redux/selectors/listing/campsite';

import Amenities from '../Amenities/Amenities';

export const CampgroundHighlightsContainer = () => {
  const intl = useIntl();

  const isCampsite = useSelector(getIsCampsite);

  const amenities = useSelector(getCampgroundLocationDetailsAmenityList);
  const availableAmenities = amenities.list.filter(amenity => amenity.available);

  if (!isCampsite) return null;

  return (
    <Amenities
      items={amenities.list}
      categories={amenities.categories}
      modalTitle={intl.formatMessage({
        defaultMessage: 'Location highlights',
        id: '2ZcIFv',
      })}
      showMoreText={intl.formatMessage(
        { defaultMessage: 'Show all {detailCount} highlights', id: '1oqgpz' },
        { detailCount: availableAmenities.length },
      )}
      header={intl.formatMessage({
        defaultMessage: 'Location highlights',
        id: '2ZcIFv',
      })}
    />
  );
};
