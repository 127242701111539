import noop from 'lodash/noop';
import { ThunkAction } from 'redux-thunk';

import { genMapboxUrl } from '@/hooks/useMapboxQuery';
import { TRootState } from '@/redux/rootReducer';
import { getListingLocation } from '@/redux/selectors/listing/page';
import apiRequest from '@/services/apiRequest';
import { EDeliveryOption } from '@/services/types/core/delivery.types';
import { IMapboxPlacesResponse } from '@/services/types/mapbox/mapboxApi';
import { IQuoteDeliveryLocation } from '@/services/types/quote/IDeliveryLocation';
import { itemizeAddress } from '@/utility/itemizeAddress';

import { TUpdateDeliveryCostActions } from '../delivery';
import { ISetDeliveryAction, setQuoteDelivery } from '../quote';
import { updateDeliveryCost } from './deliveryCost';

// @TODO replace all the BillSectionDeliveryContainer useEffect
// from search page to listing page, we are carrying the delivery info through the URL param
export const updateQuoteDeliveryFromURL =
  (
    delivery: string | string[] | undefined,
    deliveryQuery: string | string[] | undefined,
    locationId: string | string[] | undefined,
    deliveryCenter: string | string[] | undefined,
    deliveryStationary: string | string[] | undefined,
  ): ThunkAction<
    Promise<IQuoteDeliveryLocation | undefined | void> | undefined,
    TRootState,
    void,
    TUpdateDeliveryCostActions | ISetDeliveryAction
  > =>
  (dispatch, getState) => {
    if (!delivery || !deliveryQuery || !locationId || !deliveryCenter) return;

    let parsedDeliveryCenter;
    try {
      parsedDeliveryCenter = JSON.parse(deliveryCenter as string);
    } catch {
      return;
    }

    if (!parsedDeliveryCenter || parsedDeliveryCenter.length !== 2) return;

    const url = genMapboxUrl({
      limit: 10,
      text: typeof deliveryQuery === 'string' ? deliveryQuery : '',
      types: 'address,region,place,district,locality,poi',
      location: parsedDeliveryCenter,
    });

    return apiRequest<IMapboxPlacesResponse>({ url }).then(response => {
      const location = response.features.find(({ id }) => id === locationId);
      if (!location) return;
      const deliveryLocation = itemizeAddress(location);
      if (deliveryLocation) {
        dispatch(
          setQuoteDelivery({
            location: deliveryLocation,
            address: deliveryLocation.formattedAddress || '',
            stationary: deliveryStationary === EDeliveryOption.STATIONARY,
          }),
        );
        const listingLocation = getListingLocation(getState());
        dispatch(
          updateDeliveryCost(
            listingLocation,
            deliveryLocation,
            deliveryStationary as EDeliveryOption,
          ),
        );
        return deliveryLocation;
      }
    }, noop);
  };
