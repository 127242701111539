import { ThunkAction } from 'redux-thunk';

import * as OwnerActions from '@/redux/modules/owner/type';
import { TRootState } from '@/redux/rootReducer';
import { getCurrency } from '@/redux/selectors/currency';
import { getOwnerInfo, getOwnerRentals, getOwnerReviews } from '@/services/getOwnerDetail';
import { IReview } from '@/services/types/core/reviews/id';
import { IRentalResponse } from '@/services/types/search/rentals/id';
import { IUserResponse } from '@/services/types/search/users/id';

/**
 * fetch owner
 */
const fetchOwnerPendingAction = (): OwnerActions.IOwnerAction => ({
  type: OwnerActions.FETCH_OWNER_PENDING,
});

const fetchOwnerFailureAction = (): OwnerActions.IOwnerAction => ({
  type: OwnerActions.FETCH_OWNER_FAILURE,
  error: true,
});

const fetchOwnerSuccessAction = (owner: IUserResponse): OwnerActions.IOwnerAction => {
  return {
    type: OwnerActions.FETCH_OWNER_SUCCESS,
    payload: owner,
  };
};

export const fetchOwner =
  (slug: string): ThunkAction<void, TRootState, void, OwnerActions.IOwnerAction> =>
  async (dispatch, getState) => {
    const owner = getState().owner.owner;
    let ownerId = owner.id;

    if (!ownerId) {
      try {
        dispatch(fetchOwnerPendingAction());
        const ownerInfo = await getOwnerInfo(slug);
        if (ownerInfo?.data) {
          ownerId = ownerInfo.data.id;
          dispatch(fetchOwnerSuccessAction(ownerInfo));
        } else {
          dispatch(fetchOwnerFailureAction());
        }
      } catch {
        dispatch(fetchOwnerFailureAction());
      }
    }

    if (ownerId && Number(ownerId) > 0) {
      await dispatch(fetchOwnerRentals(Number(ownerId)));
      await dispatch(fetchOwnerReviews(Number(ownerId)));
    } else {
      dispatch(ownerResetAction());
    }
  };

export const fetchReviews =
  (slug: string, page = 1): ThunkAction<void, TRootState, void, OwnerActions.IOwnerAction> =>
  async (dispatch, getState) => {
    const owner = getState().owner.owner;
    let ownerId = owner.id;

    if (!ownerId) {
      try {
        dispatch(fetchOwnerPendingAction());
        const ownerInfo = await getOwnerInfo(slug);
        ownerId = ownerInfo.data.id;
        dispatch(fetchOwnerSuccessAction(ownerInfo));
      } catch {
        dispatch(fetchOwnerFailureAction());
      }
    }

    const limit = 16;
    const offset = (page - 1) * limit;
    await dispatch(fetchOwnerReviews(Number(ownerId), limit, offset));
  };

/**
 * fetch owner rentals
 */
const fetchRentalsPendingAction = (): OwnerActions.IOwnerRentalsAction => ({
  type: OwnerActions.FETCH_OWNER_RENTALS_PENDING,
});

const fetchRentalsFailureAction = (): OwnerActions.IOwnerRentalsAction => ({
  type: OwnerActions.FETCH_OWNER_RENTALS_FAILURE,
  error: true,
});

const fetchRentalsSuccessAction = (rentals: IRentalResponse): OwnerActions.IOwnerRentalsAction => {
  return {
    type: OwnerActions.FETCH_OWNER_RENTALS_SUCCESS,
    payload: rentals,
  };
};

/**
 * fetch owner stays
 */
const fetchStaysPendingAction = (): OwnerActions.IOwnerRentalsAction => ({
  type: OwnerActions.FETCH_OWNER_STAYS_PENDING,
});

const fetchStaysFailureAction = (): OwnerActions.IOwnerRentalsAction => ({
  type: OwnerActions.FETCH_OWNER_STAYS_FAILURE,
  error: true,
});

const fetchStaysSuccessAction = (stays: IRentalResponse): OwnerActions.IOwnerRentalsAction => {
  return {
    type: OwnerActions.FETCH_OWNER_STAYS_SUCCESS,
    payload: stays,
  };
};

export const fetchOwnerRentals =
  (
    userId: number,
    limit = 8,
  ): ThunkAction<void, TRootState, void, OwnerActions.IOwnerRentalsAction> =>
  async (dispatch, getState) => {
    const currency = getCurrency(getState());
    dispatch(fetchRentalsPendingAction());
    dispatch(fetchStaysPendingAction());

    try {
      const rentals = await getOwnerRentals(userId, limit, currency);
      if (!rentals) {
        throw new Error('404');
      }
      dispatch(fetchRentalsSuccessAction(rentals));
    } catch {
      dispatch(fetchRentalsFailureAction());
    }

    try {
      const stays = await getOwnerRentals(userId, limit, currency, 'stay');
      if (!stays) {
        throw new Error('404');
      }
      dispatch(fetchStaysSuccessAction(stays));
    } catch {
      dispatch(fetchStaysFailureAction());
    }
  };

/**
 * fetch owner reviews
 */
const fetchReviewsPendingAction = (): OwnerActions.IOwnerReviewsAction => ({
  type: OwnerActions.FETCH_OWNER_REVIEWS_PENDING,
});

const fetchReviewsFailureAction = (): OwnerActions.IOwnerReviewsAction => ({
  type: OwnerActions.FETCH_OWNER_REVIEWS_FAILURE,
  error: true,
});

const fetchReviewsSuccessAction = (reviews: IReview[]): OwnerActions.IOwnerReviewsAction => {
  return {
    type: OwnerActions.FETCH_OWNER_REVIEWS_SUCCESS,
    payload: reviews,
  };
};

const fetchOwnerReviews =
  (
    userId: number,
    limit = 16,
    offset = 0,
  ): ThunkAction<void, TRootState, void, OwnerActions.IOwnerReviewsAction> =>
  async dispatch => {
    dispatch(fetchReviewsPendingAction());

    try {
      const reviews = await getOwnerReviews(userId, limit, offset);
      dispatch(fetchReviewsSuccessAction(reviews));
    } catch {
      dispatch(fetchReviewsFailureAction());
    }
  };

const ownerResetAction = (): OwnerActions.IOwnerAction => {
  return {
    type: OwnerActions.FETCH_OWNER_RESET,
  };
};
