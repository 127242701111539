import React from 'react';
import { useSelector } from 'react-redux';

import { getIsStay } from '@/redux/selectors/listing/listingHeader';
import {
  getListingSeoContent,
  getStayListingSeoContent,
} from '@/redux/selectors/listing/seoContent';

import SeoContent from './SeoContent';

const SeoContentContainer = () => {
  const isStay = useSelector(getIsStay);
  const seoContent = useSelector(isStay ? getStayListingSeoContent : getListingSeoContent);
  const hasContent = seoContent && Object.values(seoContent).some(item => item.content);

  if (!hasContent) {
    return null;
  }

  return <SeoContent data={seoContent} />;
};

export default SeoContentContainer;
