import { getIntl } from './i18n';

enum ResponseTimes {
  LESS_HOUR,
  HOUR,
  SIX_HOURS,
  TWELVE_HOURS,
  DAY,
}

const formatResponseTime = (time: number, withSubject = false) => {
  const intl = getIntl();
  const minutes = time / 60;
  const hours = minutes / 60;

  const predicate: { [key in ResponseTimes]: string } = {
    [ResponseTimes.LESS_HOUR]: intl.formatMessage({
      defaultMessage: 'Typically responds in less than an hour',
      id: '94rgBZ',
    }),
    [ResponseTimes.HOUR]: intl.formatMessage({
      defaultMessage: 'Typically responds within an hour',
      id: 'B+r0xr',
    }),
    [ResponseTimes.SIX_HOURS]: intl.formatMessage({
      defaultMessage: 'Typically responds in less than 6 hours',
      id: 'VvPetO',
    }),
    [ResponseTimes.TWELVE_HOURS]: intl.formatMessage({
      defaultMessage: 'Typically responds in less than 12 hours',
      id: 'Z71yoO',
    }),
    [ResponseTimes.DAY]: intl.formatMessage({
      defaultMessage: 'Typically responds within a day',
      id: 'GB4agj',
    }),
  };

  const subject: { [key in ResponseTimes]: string } = {
    [ResponseTimes.LESS_HOUR]: intl.formatMessage({
      defaultMessage: 'The host typically responds in less than an hour',
      id: 'd3bUj6',
    }),
    [ResponseTimes.HOUR]: intl.formatMessage({
      defaultMessage: 'The host typically responds within an hour',
      id: '0l+Id9',
    }),
    [ResponseTimes.SIX_HOURS]: intl.formatMessage({
      defaultMessage: 'The host typically responds in less than 6 hours',
      id: 'OMOMDF',
    }),
    [ResponseTimes.TWELVE_HOURS]: intl.formatMessage({
      defaultMessage: 'The host typically responds in less than 12 hours',
      id: '4eTdnV',
    }),
    [ResponseTimes.DAY]: intl.formatMessage({
      defaultMessage: 'The host typically responds within a day',
      id: 'DVdiJH',
    }),
  };

  const format = withSubject ? subject : predicate;

  if (hours < 1) return format[ResponseTimes.LESS_HOUR];
  else if (hours <= 2) return format[ResponseTimes.HOUR];
  else if (hours <= 6) return format[ResponseTimes.SIX_HOURS];
  else if (hours <= 12) return format[ResponseTimes.TWELVE_HOURS];
  else if (hours <= 24) return format[ResponseTimes.DAY];

  return '';
};

export default formatResponseTime;
