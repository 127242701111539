import React from 'react';
import { FormattedMessage } from 'react-intl';

import Heading from '@/components/switchback/Heading/Heading';
import { PriceType } from '@/redux/selectors/listing/page';

interface IDiscountsProps {
  weeklyDiscountPercentage: number;
  monthlyDiscountPercentage: number;
  priceType: PriceType;
}

const strong = (msg: React.ReactNode) => <strong>{msg}</strong>;

const Discounts: React.FC<IDiscountsProps> = ({
  weeklyDiscountPercentage,
  monthlyDiscountPercentage,
  priceType,
}) => {
  if (!weeklyDiscountPercentage && !monthlyDiscountPercentage) return null;
  return (
    <div className="mt-6 mb-4">
      <Heading level={6} className="mb-1 text-base md:text-xl semiHighlight md:highlight">
        <FormattedMessage defaultMessage="Discounts" id="n+Gwbu" />
      </Heading>
      <ul className="ml-6 list-disc">
        {weeklyDiscountPercentage > 0 && (
          <li className="text-base semiHighlight text-primary-base">
            <FormattedMessage
              defaultMessage="Weekly (7+ nights): <strong>{percent}% off</strong> {
            priceType, select,
              day {daily}
              other {nightly}
            } rate"
              id="PkLaNK"
              values={{ percent: weeklyDiscountPercentage, strong, priceType }}
            />
          </li>
        )}
        {monthlyDiscountPercentage > 0 && (
          <li className="text-base semiHighlight text-primary-base">
            <FormattedMessage
              defaultMessage="Monthly (28+ nights): <strong>{percent}% off</strong> {
            priceType, select,
              day {daily}
              other {nightly}
            } rate"
              id="vt/rOY"
              values={{ percent: monthlyDiscountPercentage, strong, priceType }}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

export default Discounts;
