import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import AddOnsModal from '@/components/ui/AddOnsModal/AddOnsModal';
import { BillModuleStepButton } from '@/components/ui/BillModule/BillModuleStepButton/BillModuleStepButton';
import { getBillSummary } from '@/redux/selectors/bill/summary';
import { getAddonsWithQuantitySelected } from '@/redux/selectors/listing/addons';
import { getListingId } from '@/redux/selectors/listing/page';
import { getQuoteAddons } from '@/redux/selectors/quote';
import { trackEvent } from '@/services/track-event';

type TBillModuleAddOnsProps = {
  updateStep?: () => void;
  withDefaults?: boolean;
  completed?: boolean;
};

const BillModuleAddOnsSelector = ({
  withDefaults,
  completed,
  numAvailableAddons,
  numSelectedAddons,
  handleOpenAddonsModal,
}: {
  withDefaults: boolean | undefined;
  completed: boolean | undefined;
  numAvailableAddons: number | undefined;
  numSelectedAddons: number | undefined;
  handleOpenAddonsModal: () => void;
}) => {
  if (completed || withDefaults) {
    return (
      <BillModuleStepButton
        outlined={false}
        text={<FormattedMessage defaultMessage="Add-ons" id="5lP56d" />}
        linkText={
          !numSelectedAddons ? (
            <FormattedMessage
              defaultMessage="{numAvailableAddons} available"
              id="7xZqLd"
              values={{ numAvailableAddons }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{numSelectedAddons} selected"
              id="XXJmlD"
              values={{ numSelectedAddons }}
            />
          )
        }
        onClick={handleOpenAddonsModal}
      />
    );
  }

  return (
    <>
      <p className="my-3 font-medium">
        <FormattedMessage defaultMessage="Add-ons" id="5lP56d" />
      </p>

      <div>
        <BillModuleStepButton
          outlined={true}
          text={
            <FormattedMessage
              defaultMessage="{numAvailableAddons} Available"
              id="+E1bCF"
              values={{ numAvailableAddons }}
            />
          }
          linkText={<FormattedMessage defaultMessage="Select" id="kQAf2d" />}
          onClick={handleOpenAddonsModal}
        />
      </div>
    </>
  );
};

export const BillModuleAddOns: React.FC<TBillModuleAddOnsProps> = ({
  updateStep,
  withDefaults,
  completed,
}) => {
  const rentalId = useSelector(getListingId);
  const quoteAddons = useSelector(getQuoteAddons);
  const summary = useSelector(getBillSummary);
  const availableItems = useSelector(getAddonsWithQuantitySelected);

  const [isAddonsModalOpen, setIsAddonsModalOpen] = useState(false);
  const [hasPendingAddon, setHasPendingAddon] = useState(false);

  const memorizedAddons = useMemo(
    () => quoteAddons?.map(addon => ({ name: addon.name, total: addon.total })),
    [quoteAddons],
  );

  const trackAddonEvents = () => {
    if (rentalId && hasPendingAddon) {
      const addons = (memorizedAddons || []).reduce((acc, addon) => {
        return addon.name ? { ...acc, [addon.name]: (addon.total || 0) / 100 } : acc;
      }, {});

      trackEvent({
        event: 'Listing : Bill addons',
        rental_id: rentalId,
        add_on: addons,
      });

      setHasPendingAddon(false);
    }
  };

  const handleOpenAddonsModal = () => {
    setIsAddonsModalOpen(true);
    trackAddonEvents();
  };

  const handleCloseAddonsModal = () => {
    updateStep?.();
    setIsAddonsModalOpen(false);
    setHasPendingAddon(true);
  };

  return (
    <>
      <BillModuleAddOnsSelector
        withDefaults={withDefaults}
        completed={completed}
        numAvailableAddons={availableItems?.length}
        numSelectedAddons={summary?.addonsQuantity}
        handleOpenAddonsModal={handleOpenAddonsModal}
      />

      <AddOnsModal
        isModalOpen={isAddonsModalOpen}
        onOpenModal={handleOpenAddonsModal}
        onCloseModal={handleCloseAddonsModal}
      />
    </>
  );
};
