import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CARET_SMALL } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import Link from '@/components/switchback/Link/Link';
import Text from '@/components/switchback/Text/Text';
import { ROADSIDE_ASSISTANCE } from '@/constants/urls';

import css from './RateDetails.module.css';

const RateDetails: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      <div className="flex items-center mt-6">
        <Text type="block" className="mr-4 h5">
          <FormattedMessage defaultMessage="24/7 roadside assistance" id="S08lV5" />
        </Text>

        <Link
          data-testid="rules-learn-more-link"
          href={ROADSIDE_ASSISTANCE}
          target="_blank"
          className={css.learnMore}
          rel="noreferrer noopener">
          {intl.formatMessage({
            defaultMessage: 'Learn more ',
            id: 'cQ/nNz',
          })}{' '}
          <Icon
            width={24}
            height={24}
            className={'translate-y-0.5 -translate-x-1.5'}
            name={CARET_SMALL}
          />
          <span className="sr-only">
            {intl.formatMessage({
              defaultMessage: 'about Roadside Assistance',
              id: '+o7Xo/',
            })}
          </span>
        </Link>
      </div>
      <Text type="paragraph" className="mt-1 text-base semiHighlight text-secondary-base">
        <FormattedMessage
          defaultMessage="Roadside assistance on every rental, plus help planning your trip or operating the RV."
          id="0bzFvG"
        />
      </Text>
      <Link
        data-testid="rules-learn-more-link"
        className="block mt-2 font-bold semiHighlight md:hidden w-content "
        href={ROADSIDE_ASSISTANCE}
        target="_blank"
        rel="noreferrer noopener">
        {intl.formatMessage({
          defaultMessage: 'Learn more ',
          id: 'cQ/nNz',
        })}{' '}
        <Icon
          width={24}
          height={24}
          className={'translate-y-0.5 -translate-x-1.5'}
          name={CARET_SMALL}
        />
        <span className="sr-only">
          {intl.formatMessage({
            defaultMessage: 'about Roadside Assistance',
            id: '+o7Xo/',
          })}
        </span>
      </Link>
    </>
  );
};

export default RateDetails;
