import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@/components/switchback/Heading/Heading';
import { TCurrency } from '@/config/locales';
import { IAddOn } from '@/redux/selectors/listing/addons';
import { getListingCanChargeSecurityDeposit } from '@/redux/selectors/listing/page';
import { formatCurrency } from '@/utility/currency';

interface IFeesProps {
  securityDeposit: number;
  prepFee: number | undefined;
  prepFeeDescription: string | undefined;
  currency: TCurrency;
  addons: IAddOn[];
}

const strong = (msg: React.ReactNode) => <strong>{msg}</strong>;

const Fees: React.FC<IFeesProps> = ({
  securityDeposit,
  prepFee,
  prepFeeDescription,
  currency,
  addons,
}) => {
  const canChargeSecurityDeposit = useSelector(getListingCanChargeSecurityDeposit);

  // Don't show this section if all of these are not present
  if (canChargeSecurityDeposit) {
    if ((prepFee == null || prepFee === 0) && !addons?.length) return null;
  } else {
    if ((prepFee == null || prepFee === 0) && !securityDeposit && !addons?.length) return null;
  }

  return (
    <div>
      <Heading level={6} className="mb-1 text-base md:text-xl md:highlight semiHighlight">
        <FormattedMessage defaultMessage="Fees" id="qZbedW" />
      </Heading>
      <ul className="ml-6 list-disc">
        {canChargeSecurityDeposit && !!securityDeposit && (
          <li className="text-base semiHighlight text-primary-base">
            <FormattedMessage
              defaultMessage="Security deposit: <strong>{price}</strong> (refundable)"
              id="PMLYkw"
              values={{
                price: formatCurrency({
                  currency,
                  digits: 2,
                  priceInCents: securityDeposit,
                }),
                strong,
              }}
            />
          </li>
        )}
        {prepFee != null && prepFee > 0 && (
          <li className="text-base semiHighlight text-primary-base">
            <FormattedMessage
              defaultMessage="Prep fee: <strong>{price}</strong>"
              id="KATXD6"
              values={{
                price: formatCurrency({
                  currency,
                  digits: 2,
                  priceInCents: prepFee,
                }),
                strong,
              }}
            />
            {!!prepFeeDescription && `. ${prepFeeDescription}`}
          </li>
        )}
        {addons.map((item: IAddOn) => (
          <li key={item.id} className="text-base semiHighlight text-primary-base">
            <FormattedMessage
              defaultMessage="{name}: <strong>{price}</strong>"
              id="62/WtY"
              values={{
                name: item.name,
                price: formatCurrency({
                  currency,
                  digits: 2,
                  priceInCents: Number(item.price),
                }),
                strong,
              }}
            />
            {!!item.description && `. ${item.description}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Fees;
