import {
  ETagVariant,
  ETextStyleVariant,
  Icon as BonfireIcon,
  Tag,
  Text as BonfireText,
} from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProBadge } from '@/components/common/ProBadge';
import Heading, { THeadingLevels } from '@/components/switchback/Heading/Heading';
import Icon, { IconType } from '@/components/switchback/Icon/IconComponent';
import Text from '@/components/switchback/Text/Text';
import { listingPageAnchors } from '@/constants/anchors';
import { trackFeatureViewedEvent } from '@/services/analytics/features';
import { EFeatureName, EFeatureSource } from '@/services/analytics/features/types';
import { ECampsiteCategoryType } from '@/services/types/search/rentals/id';

import Rating from '../Rating/Rating';
import css from './ListingHeader.module.css';

interface IInfo {
  label: string;
  icon: IconType;
}

export interface IProps {
  title?: string;
  infos?: IInfo[];
  ratingNumber?: number;
  reviewsCount?: number;
  accommodationType?: string;
  campsiteCategoryType?: string;
  locationPrefix?: string;
  location?: string;
  headingLevel?: THeadingLevels;
  loading: boolean;
  showProBadge?: boolean;
  rentalId?: number;
  dealer?: boolean;
  onLinkClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => void;
}

const ListingHeader: React.FC<IProps> = ({
  title = '',
  headingLevel = 1,
  ratingNumber,
  reviewsCount,
  accommodationType = '',
  campsiteCategoryType = '',
  locationPrefix,
  location = '',
  onLinkClick,
  loading = false,
  infos,
  showProBadge = false,
  rentalId,
  ...props
}) => {
  const intl = useIntl();
  const reviewsLinkAriaLabel = intl.formatMessage(
    { defaultMessage: '{reviews, plural, one {# review} other {# reviews}}', id: 'pvspih' },
    { reviews: reviewsCount },
  );

  return (
    <header data-loading={loading} className={css.listingHeader} {...props}>
      {campsiteCategoryType === ECampsiteCategoryType.STATIONARY_CAMPER_SITE && (
        <div className="pb-4">
          <Tag label="Limited offer" variant={ETagVariant.Success} />
        </div>
      )}
      <div className="flex flex-col md:flex-col-reverse">
        <div className="items-center justify-start order-1 inline mb-2 md:mb-6">
          {showProBadge && (
            <ProBadge
              icon="General.Storefront.Detailed"
              className="inline-block mr-2"
              onMouseEnter={() => {
                trackFeatureViewedEvent({
                  featureName: EFeatureName.PRO_BADGE,
                  rentalID: rentalId,
                  source: EFeatureSource.LISTING_HEADER,
                });
              }}
            />
          )}
          <Heading
            level={headingLevel}
            className={`${css.title} ${css.titleWrap} md:h2 text-850 semiHighlight font-bold inline`}>
            {title}
          </Heading>
        </div>
        <div className="flex-wrap items-center order-2 block pr-2 mb-4 mr-2 md:flex">
          {(!!ratingNumber || loading) && (
            <div className="flex items-center pr-2 mb-2 mr-2 border-gray-300 md:border-r md:mb-0">
              <Rating
                className="mr-1 text-sm font-bold text-gray-800 semiHighlight"
                number={ratingNumber || 0}
              />
              {` • `}
              <a
                data-testid="reviews-score-btn"
                href={`#${listingPageAnchors.REVIEWS}`}
                className={`${css.info} ml-1 relative flex text-sm border-none text-primary-base underline semiHighlight`}
                onClick={e => onLinkClick(e, listingPageAnchors.REVIEWS)}>
                {reviewsLinkAriaLabel}
              </a>
            </div>
          )}
          <div className={`${css.info} relative semiHighlight text-sm text-black`}>
            <Text type="inline">{accommodationType}</Text>
            <span className="mx-1">{` • `}</span>
            {locationPrefix && <span className="mr-1">{locationPrefix}</span>}
            <a
              className="text-black underline cursor-pointer hover:no-underline"
              href={`#${listingPageAnchors.LOCATION}`}
              onClick={e => onLinkClick(e, listingPageAnchors.LOCATION)}>
              {location}
            </a>
          </div>
        </div>
      </div>
      <div className="pb-4 overflow-auto md:-mb-4 md:overflow-x-visible whitespace-nowrap md:whitespace-normal md:pb-0">
        {infos &&
          infos.map(info => (
            <div
              key={info.label}
              className="inline-flex items-center px-4 py-1 mr-3 rounded-full md:mb-4 bg-grey-100">
              <Icon name={info.icon} className="mr-2" height={16} width={16} />
              <div className="text-sm font-normal text-gray-900 md:text-base semiHighlight">
                {info.label}
              </div>
            </div>
          ))}
      </div>
      {campsiteCategoryType === ECampsiteCategoryType.STATIONARY_CAMPER_SITE && (
        <div className="flex items-center pt-12 gap-4">
          <div className="p-4 rounded-full bg-green-30">
            <BonfireIcon name="Terrain.Terrain.Woods" className="text-2xl" />
          </div>
          <div>
            <BonfireText variant={ETextStyleVariant.MediumBold}>
              <FormattedMessage defaultMessage="Glamping comfort, close to nature." id="OfbY7W" />
            </BonfireText>
            <BonfireText variant={ETextStyleVariant.SmallRegular}>
              <FormattedMessage
                defaultMessage="This camper is parked in one of our Premium sites."
                id="XI1YZR"
              />
            </BonfireText>
          </div>
        </div>
      )}
    </header>
  );
};

export default ListingHeader;
