import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Heading from '@/components/switchback/Heading/Heading';
import Text from '@/components/switchback/Text/Text';
import { PriceDetails } from '@/components/ui/ListingTile/PriceDetails';
import { IPricingInformation } from '@/redux/selectors/listing/bill';
import { getIsCampground } from '@/redux/selectors/listing/listingHeader';

import css from './BillSectionMobileBar.module.css';

interface IProps {
  cta: string;
  onRequestClick?: () => void;
  price: IPricingInformation;
  isPublished?: boolean;
  forceInactive?: boolean;
  inactiveHref?: string;
  loading?: boolean;
}

const BillSectionMobileBar: React.FC<IProps> = ({
  onRequestClick,
  price,
  isPublished = true,
  forceInactive = false, // force this listing to show up as inactive
  inactiveHref = '',
  cta,
  loading = false,
}) => {
  const intl = useIntl();
  const isCampground = useSelector(getIsCampground);

  const { originalPrice, overrideDayPrice = 0 } = price;
  const originalPriceValue = (isCampground && overrideDayPrice) || originalPrice;

  if (!isPublished || forceInactive) {
    return (
      <div
        className="overflow-hidden -mb-t shadow-200"
        data-testid="unpublished-bill"
        data-loading={loading}>
        <div
          className={`sm:flex sm:items-end px-4 py-5 bg-white sm:p-8 pointer-events-auto ${css.bar} ${css.transition}`}>
          <div className="sm:max-w-xs">
            <Heading level={2} className="mb-4 highlight autoType800">
              {intl.formatMessage({
                defaultMessage: 'Oops, this rig is no longer available.',
                id: 'kgJOtG',
              })}
            </Heading>
            <Text type="paragraph" className="text autoType300">
              {intl.formatMessage({
                defaultMessage: "Luckily, there's another option out there for you.",
                id: 'gKpA4/',
              })}
            </Text>
          </div>
          <Button
            href={inactiveHref}
            className="mt-6 ml-auto text-center sm:mt-0"
            variant={EButtonColorVariant.Primary}
            label={intl.formatMessage({
              defaultMessage: 'See other options',
              id: 'cBuuQQ',
            })}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-hidden -mb-t shadow-200">
      <div
        className={`flex flex-col bg-white pointer-events-auto ${css.transition}`}
        data-loading={loading}>
        <div className="flex flex-row px-4 py-5 md:px-8 md:py-7">
          <PriceDetails {...price} showDiscount originalPrice={originalPriceValue} />
          <Button
            className="ml-auto"
            type="button"
            variant={EButtonColorVariant.Primary}
            label={cta}
            onClick={onRequestClick}
          />
        </div>
      </div>
    </div>
  );
};

export default BillSectionMobileBar;
